import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Globals } from '../../globals';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-new-folder',
  templateUrl: './new-folder.component.html',
  styleUrls: ['./new-folder.component.css']
})
export class NewFolderComponent implements OnInit {
  folder: any = {
    name: '',
    releaseIds: [],
    trackIds: [],
    videoIds: [],
    assetIds: []
  };

  id: number;
  private sub: any;
  addTag;
  constructor(private router: Router, private route: ActivatedRoute, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id)
        this.getFolder(this.id);

    });
  }

  getFolder(id) {
    this.http.get('/folders/' + id, 0)
      .subscribe((data) => {
        this.folder = data;
      });
  }

  saveFolder() {
    if (this.id) {
      this.http.put('/folders/' + this.id, this.folder, {})
        .subscribe((data) => {
          this.notifier.notify('success', 'Updated Successfully');
        });
    } else {
      this.http.post('/folders', this.folder, {})
        .subscribe((data) => {
          this.notifier.notify('success', 'Created Successfully');
        });
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  delete() {
    this.http.delete('/folders/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['folders']);
      });

  }

}