import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.css']
})
export class TracksComponent implements OnInit {

  tracks: {}[] = [];
  totalPages = 0;
  currentPage = 0;
  singleItemToDelete = -1;

  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle = '';
  searchText = '';
  searchLabel = '';
  searchFormat = '';
  searchInstrumental = '';

  constructor(private notifier: NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http: ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Tracks'
    this.getTracks(1);
  }

  getTracks(page) {
    let filter = { page: page };
    if (this.searchText && this.searchText != '')
      filter['text'] = this.searchText;

    if (this.searchLabel && this.searchLabel != '')
      filter['label'] = this.searchLabel;

    if (this.searchFormat && this.searchFormat != '')
      filter['format'] = this.searchFormat;

    if (this.searchInstrumental && this.searchInstrumental != '')
      filter['instrumental'] = this.searchInstrumental === 'Yes' ? true : false;


    this.http.get('/tracks', filter)
      .subscribe((data) => {
        this.tracks = data['result'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
        this.getArtistNames();
      });
  }

  getArtistNames() {
    for (let i = 0; i < this.tracks.length; i++) {
      let artists = '';

      let element = this.tracks[i];

      for (let j = 0; j < element['primaryArtistIds'].length; j++) {
        const artist = element['primaryArtistIds'][j];
        artists += artist['name'] + ', ';
      }

      for (let j = 0; j < element['featuringArtistIds'].length; j++) {
        const artist = element['featuringArtistIds'][j];
        artists += artist['name'] + ', ';
      }

      element['artistIds'] = artists;
      element['artistIds'] = element['artistIds'].substring(0, element['artistIds'].length - 2)
    }
  }

  editTrack(id) {
    this.router.navigate(['./' + id + '/edit'], { relativeTo: this.route });
  }

  onCreateTrack() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  deleteSingleItem(i) {
    this.http.delete('/tracks/' + this.tracks[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getTracks(this.currentPage);
      });

  }


  isSelected(id) {
    if (this.selectedItemsOnInit)
      return true;

    this.selectedItemsOnInit = false;

    if (this.selectedItems.indexOf(id) > -1)
      return true;
    else
      return false;
  }

  addToSelectedItems(id) {
    if (this.selectedItems.indexOf(id) > -1) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    }
    else {
      this.selectedItems.push(id);
    }
  }

  deleteMultipleItems() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/tracks/' + this.selectedItems[i], {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Deleted Successfully');
          this.getTracks(this.currentPage);
        });


    }
  }

  export() {
    this.http.post('/tracks/export', { ids: this.selectedItems }, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Exported Successfully');
      });
  }

  import(files: FileList) {

    const formData: FormData = new FormData();
    formData.append('file', files[0], files[0].name);

    const progress = new Subject<number>();

    this.http.postWithProgress('/tracks/import', formData)
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          console.log(percentDone);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      });
  }
}
