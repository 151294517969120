import { Component, OnInit, HostListener } from '@angular/core';
import { Globals } from '../../globals';
import { ApiService } from '../../api.service';
import { NotifierService } from 'angular-notifier';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit, ComponentCanDeactivate {
  email = '';
  originalEmail = '';

  constructor(private router: Router, private notifier: NotifierService, private http: ApiService, private globals: Globals) { }

  ngOnInit() {
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.email === this.originalEmail)
      return true;
    else
      return false;
  }
  inviteUser() {
    this.http.post('/users/invite', { email: this.email }, {})
      .subscribe((data) => {
        if (data['error']) {
          this.notifier.notify('error', data['error']);
        } else {
          this.notifier.notify('success', 'Invitation Sent Successfully');
          this.originalEmail = this.email;
          this.router.navigate(['users']);
        }

      });
  }
}
