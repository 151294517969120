import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  videos : {}[] = [];
  totalPages = 0;
  currentPage = 0;
  singleItemToDelete = -1;

  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle= '';
  constructor(private notifier : NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http : ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Videos'
    this.getVideos(1);
  }

  getVideos(page){
    let filter = { page: page };
    if(this.searchTitle && this.searchTitle != '')
      filter['searchTitle'] = this.searchTitle;

    this.http.get('/videos', filter)
    .subscribe((data) => {
      this.videos = data['result'];
      this.totalPages = data['meta']['totalPages'];
      this.currentPage = data['meta']['currentPage'];
      
      this.getArtistNames();
    });
  }

  getArtistNames(){
    for (let i = 0; i < this.videos.length; i++) {
      let artists = '';

      let element = this.videos[i];

      for (let j = 0; j < element['primaryArtistIds'].length; j++) {
        const artist = element['primaryArtistIds'][j];
        artists += artist['name']+', ';
      }
      
      for (let j = 0; j < element['featuringArtistIds'].length; j++) {
        const artist = element['featuringArtistIds'][j];
        artists += artist['name']+', ';
      }

      element['artistIds'] = artists;
      element['artistIds'] = element['artistIds'].substring(0,element['artistIds'].length-2)
    }
  }

  editVideo(id){
    this.router.navigate(['./'+id+'/edit'], { relativeTo: this.route });
  }

  onCreateVideo(){
    this.router.navigate(['./new'], {relativeTo: this.route});
  }

  deleteSingleItem(i) {
    this.http.delete('/videos/' + this.videos[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getVideos(this.currentPage);
      });
  }

  isSelected(id){
    if(this.selectedItemsOnInit)
      return true;
    
    this.selectedItemsOnInit = false;  
    
      if(this.selectedItems.indexOf(id)>-1)
      return true;
    else 
      return false;
  }

  addToSelectedItems(id){
    if(this.selectedItems.indexOf(id)>-1){
      this.selectedItems.splice( this.selectedItems.indexOf(id), 1);
    }
    else{
      this.selectedItems.push(id);
    }
  }

  deleteMultipleItems(){
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/videos/' + this.selectedItems[i], {})
      .subscribe((data) => {
        this.selectedItems.splice( this.selectedItems.indexOf(this.selectedItems[i]), 1);
        this.notifier.notify('success', 'Deleted Successfully');
        this.getVideos(this.currentPage);
      });

      
    }
  }
}