import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projects: {}[] = [];
  totalPages = 0;
  currentPage = 1;
  singleItemToDelete = -1;
  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle = '';

  constructor(private notifier: NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http: ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Projects'
    this.getProjects(1);
  }

  getProjects(page) {
    let filter = { page: page };
    if(this.searchTitle && this.searchTitle != '')
      filter['searchTitle'] = this.searchTitle;

    this.http.get('/projects', filter)
      .subscribe((data) => {
        this.projects = data['result'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];

        this.getArtistNames();
      });
  }

  editProject(id) {
    this.router.navigate(['./' + id + '/edit'], { relativeTo: this.route });
  }

  onCreateProject() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  deleteSingleItem(i) {
    this.http.delete('/projects/' + this.projects[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getProjects(this.currentPage);
      });

  }

  isSelected(id) {
    if (this.selectedItemsOnInit)
      return true;

    this.selectedItemsOnInit = false;

    if (this.selectedItems.indexOf(id) > -1)
      return true;
    else
      return false;
  }

  addToSelectedItems(id) {
    if (this.selectedItems.indexOf(id) > -1) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    }
    else {
      this.selectedItems.push(id);
    }
  }

  getArtistNames() {
    for (let i = 0; i < this.projects.length; i++) {
      let artists = '';

      let element = this.projects[i];

      for (let j = 0; j < element['artistIds'].length; j++) {
        const artist = element['artistIds'][j];
        artists += artist['name'] + ', ';
      }

      element['artistIds'] = artists;
      element['artistIds'] = element['artistIds'].substring(0, element['artistIds'].length - 2)
    }
  }

  deleteMultipleItems() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/projects/' + this.selectedItems[i], {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Deleted Successfully');
          this.getProjects(this.currentPage);
        });


    }
  }


  releaseSchedule() {
    this.http.get('/projects/release-schedule', {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Release schedule generated successfully');
      });
  }

}
