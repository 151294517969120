import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  user = {
    password: '',
    passwordVerification: ''
  };
  token ='';
  error = '';
  success = '';
  sub : any;

  constructor(private globals: Globals, private http: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.globals.currentPage = 'ForgotPassword'

    this.sub = this.route.params.subscribe(params => {
      this.user['token'] = params['token'];
    });

  }

  resetPassword() {
    this.error = '';
    this.success = '';

    if (this.user.password != this.user.passwordVerification) {
      this.error = 'Passwords don\'t match';
    } else {
      this.http.post('/users/reset_password', this.user, {})
        .subscribe((data) => {
          if (data['errorNotFound']) {
            this.success = '';
            this.error = data['errorNotFound'];
          } else {
            this.error = '';
            this.success = 'Password reset successfully.';
            this.router.navigate(['login']);
          }
        });
    }
  }

}
