import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: {}[] = [];
  totalPages = 0;
  currentPage = 0;
  singleItemToDelete = -1;

  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle = '';

  constructor(private notifier: NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http: ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Users'
    this.getUsers(1);
  }

  getUsers(page) {
    let filter = { page: page };
    if (this.searchTitle && this.searchTitle != '')
      filter['searchTitle'] = this.searchTitle;

    this.http.get('/users', filter)
      .subscribe((data) => {
        this.users = data['result'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];

      });
  }

  onCreateUser() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  deleteSingleItem(i) {
    this.http.delete('/users/' + this.users[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getUsers(this.currentPage);
      });

  }


  isSelected(id) {
    if (this.selectedItemsOnInit)
      return true;

    this.selectedItemsOnInit = false;

    if (this.selectedItems.indexOf(id) > -1)
      return true;
    else
      return false;
  }

  addToSelectedItems(id) {
    if (this.selectedItems.indexOf(id) > -1) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    }
    else {
      this.selectedItems.push(id);
    }
  }

  deleteMultipleItems() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/users/' + this.selectedItems[i], {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Deleted Successfully');
          this.getUsers(this.currentPage);
        });
    }
  }
}
