import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Globals } from '../../globals';
import { NotifierService } from 'angular-notifier';
import { HttpEventType } from '@angular/common/http';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-asset',
  templateUrl: './new-asset.component.html',
  styleUrls: ['./new-asset.component.css']
})
export class NewAssetComponent implements OnInit, ComponentCanDeactivate {
  asset: any = {
    name: '',
    s3Key: '',
    type: '',
    fileMetadata: {},
    encodings: [],
    Artists: [],
    projectIds: [],
    metaDataKeys: [],
    publicLink: ''
  };
  originalAsset = {};
  addTag;
  id: number;
  private sub: any;
  artists: [{}] = [{ _id: 'null', name: 'Loading..' }];

  constructor(private router: Router, private route: ActivatedRoute, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalAsset = JSON.parse(JSON.stringify(this.asset));
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id)
        this.getAsset(this.id);

      this.getArtists();
    })
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.asset) === JSON.stringify(this.originalAsset))
      return true;
    else
      return false;
  }


  getAsset(id) {
    this.http.get('/assets/' + id, 0)
      .subscribe((data) => {
        this.asset = data;
        if (this.asset['fileMetadata'] != {}) {
          this.asset['metaDataKeys'] = [];
          for (var key in this.asset['fileMetadata']) {
            if (this.asset['fileMetadata'].hasOwnProperty(key)) {
              this.asset['metaDataKeys'].push(key);
            }
          }
          this.originalAsset = JSON.parse(JSON.stringify(this.asset));
        }
      });
  }

  saveAsset() {
    if (this.id) {
      this.http.put('/assets/' + this.id, this.asset, {})
        .subscribe((data) => {
          this.notifier.notify('success', 'Updated Successfully');
        });
    } else {
      this.http.post('/assets', this.asset, {})
        .subscribe((data) => {
          this.notifier.notify('success', 'Created Successfully');
          this.asset = this.originalAsset;
          this.router.navigate(['assets/' + data['_id'] + '/edit']);
        });
    }
  }
  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  assetUploadError() {
    this.notifier.notify('error', 'Asset needs to be saved before uploading a file.');
  }

  delete() {
    this.http.delete('/assets/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['assets']);
      });

  }
  encodeAudio(bitDepth, bitRate, fileFormat, name) {
    this.http.post('/assets/' + this.id + '/encodeAudio', { bitDepth: bitDepth, bitRate: bitRate, fileFormat: fileFormat, sampleRate: 44100, name: name }, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Encoded Successfully');
        this.getAsset(this.id);
      });
  }

  encodeImage(width: HTMLInputElement, height: HTMLInputElement, format: HTMLInputElement) {
    this.http.post('/assets/' + this.id + '/encodeImage', { width: width.value, height: height.value, format: format.value }, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Encoded Successfully');
        this.getAsset(this.id);
      });
  }

  send(email: HTMLInputElement, format: HTMLInputElement) {
    let query;
    if (this.asset['type'] == 'Audio')
      query = { email: email.value, format: format.value };
    else
      query = { email: email.value };

    this.http.post('/assets/' + this.id + '/send', query, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Sent Successfully');
      });
  }

  uploadAsset(files: FileList) {
    let file = files.item(0);

    this.http.post('/assets/' + this.id + '/upload', { filename: this.stripFileName(file.name), type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.id] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id] = 0;

            this.notifier.notify('success', 'File Uploaded Successfully');

            this.asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

            this.http.put('/assets/' + this.id, this.asset, {})
              .subscribe((data) => {
                this.getAsset(this.id);
              });
          }
        });
      });
  }


  stripFileName(name) {
    name = name.replace('&', '');

    return name;
  }
}
