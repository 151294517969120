import { Component, ViewEncapsulation } from '@angular/core';
import { Globals } from './globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  
  constructor(public globals: Globals) {

    //Initialize years for selects.
    for (var i = new Date().getFullYear() + 1; i >= 1970; i--) {
      this.globals.years.push(i);
    }
  }

  title = 'Cooking Vinyl';
}
