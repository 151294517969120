import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  user = {
    email: ''
  }

  error = ''
  success = ''

  constructor(private globals: Globals, private http: ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'ForgotPassword'
  }

  resetPassword() {
    this.error = '';
    this.success = '';
    
    this.http.post('/users/forgot_password', this.user, {})
      .subscribe((data) => {
        if (data['errorNotFound']) {
          this.success = '';
          this.error = data['errorNotFound'];
        }else{
          this.error = '';
          this.success = 'Password reset email sent successfully.';
        }
      });
  }

}
