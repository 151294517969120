import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.css']
})
export class FoldersComponent implements OnInit {

  folders : {}[] = [];
  totalPages : number[] = [];
  currentPage : number = 0;
  singleItemToDelete = -1;
  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;

  constructor(private notifier : NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http : ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Folders'
    this.getFolders(1);
  }

  getFolders(page){
    this.http.get('/folders', {page: page})
    .subscribe((data) => {
      this.folders = data['result'];
      this.totalPages = Array(parseInt(data['meta']['totalPages'])).fill(0).map((x,i)=>i);
      this.currentPage = data['meta']['currentPage'];

    });
  }

  editFolder(id){
    this.router.navigate(['./'+id+'/edit'], { relativeTo: this.route });
  }

  onCreateFolder(){
    this.router.navigate(['./new'], {relativeTo: this.route});
  }

  deleteSingleItem(i) {
    this.http.delete('/folders/' + this.folders[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getFolders(this.currentPage);
      });

  }


  isSelected(id){
    if(this.selectedItemsOnInit)
      return true;
    
    this.selectedItemsOnInit = false;  
    
      if(this.selectedItems.indexOf(id)>-1)
      return true;
    else 
      return false;
  }

  addToSelectedItems(id){
    if(this.selectedItems.indexOf(id)>-1){
      this.selectedItems.splice( this.selectedItems.indexOf(id), 1);
    }
    else{
      this.selectedItems.push(id);
    }
  }

  deleteMultipleItems(){
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/folders/' + this.selectedItems[i], {})
      .subscribe((data) => {
        this.selectedItems.splice( this.selectedItems.indexOf(this.selectedItems[i]), 1);
        this.notifier.notify('success', 'Deleted Successfully');
        this.getFolders(this.currentPage);
      });

      
    }
  }
}