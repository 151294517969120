import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-accordion',
  template: `
    <h2 class="accordion-head">
      <div class="row">
        <div class="col-xs-8" (click)="onClick($event)">
          <span class="accordion-head-title">{{ (this.index + 1) + ': ' + title }}</span>
        </div>
        <div class="col-xs-4">
          <div class="pull-right">
            <span class="accordion-head-actions-item" (click)="delete($event)"><em class="fa fa-trash-o"></em></span>
            <span class="accordion-head-actions-item" (click)="copy($event)"><em class="fa fa-copy"></em></span>
          </div>
        </div>
      </div>
    </h2>
    <div class="accordion-body" [class.active]="active">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .accordion-head {
        cursor: pointer;
        border-top-left-radius: 5.5px;
        border-top-right-radius: 5.5px;
        background: #f5f5f5;
        padding: 10px;
        font-size: 16px;
      }

      h2.accordion-head {
        margin: 10px 0 0 0 !important;
      }

      .accordion-head-actions-item {
        margin-left: 8px;
      }

      .accordion-head-actions-item:hover {
        color: #994343;
      }

      .accordion-body {
        overflow: hidden;
        max-height: 0;
        transition: all 1s ease-in-out;
      }

      .accordion-body.active {
        display: block;
        max-height: 15000px;
      }
    `
  ],
})
export class AccordionComponent {

  @Input() index: number;

  @Input() title: string;

  @Input() active: boolean = false;

  @Output() toggleAccordion: EventEmitter<boolean> = new EventEmitter();

  @Output() onDelete = new EventEmitter();
  @Output() onCopy = new EventEmitter();

  constructor() {}

  onClick(event) {
    event.preventDefault();
    this.toggleAccordion.emit(this.active);
  }

  delete(event) {
    event.preventDefault();
    this.onDelete.emit({ index: this.index });
  }

  copy(event) {
    event.preventDefault();
    this.onCopy.emit({ index: this.index });
  }

}
