import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NotifierModule } from 'angular-notifier';
import { LoadingModule, ANIMATION_TYPES } from 'ngx-loading';
import { OAuthModule } from 'angular-oauth2-oidc';

import { Globals } from './globals';
import { AuthGuard } from './auth/auth.guard';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from './app.component';
import { AppNavbarComponent } from './navbar/navbar.component';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { NewProjectComponent } from './projects/new-project/new-project.component';
import { ReleasesComponent } from './releases/releases.component';
import { NewReleaseComponent } from './releases/new-release/new-release.component';
import { ExternalReleaseComponent } from './releases/external-release/external-release.component';
import { TracksComponent } from './tracks/tracks.component';
import { NewTrackComponent } from './tracks/new-track/new-track.component';
import { UpdateTrackComponent } from './tracks/new-track/update-track.component';
import { ExternalTrackComponent } from './tracks/external-track/external-track.component';
import { VideosComponent } from './videos/videos.component';
import { NewVideoComponent } from './videos/new-video/new-video.component';
import { ArtistsComponent } from './artists/artists.component';
import { NewArtistComponent } from './artists/new-artist/new-artist.component';
import { AssetsComponent } from './assets/assets.component';
import { NewAssetComponent } from './assets/new-asset/new-asset.component';
import { FoldersComponent } from './folders/folders.component';
import { NewFolderComponent } from './folders/new-folder/new-folder.component';
import { TerritoriesComponent } from './shared-components/territories/territories.component';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { CatalogueComponent } from './shared-components/catalogue/catalogue.component';
import { SettingsComponent } from './settings/settings.component';
import { UsersComponent } from './users/users.component';
import { NewUserComponent } from './users/new-user/new-user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PendingChangesGuard } from './libs/pending-changes.guard';
import { DragulaModule } from 'ng2-dragula';
import {NgbPaginationModule,} from '@ng-bootstrap/ng-bootstrap';
import {AccordionComponent} from './shared-components/accordion/accordion.component';
import {AccordionGroupComponent} from './shared-components//accordion/accordion-group.component';
import { ProductSheetsComponent } from './product-sheets/sheets.component';
import { EditSheetComponent } from './product-sheets/edit/sheet.component';


const appRoutes: Routes = [
  { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
  { path: 'projects/new', component: NewProjectComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'projects/:id/edit', component: NewProjectComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'releases', component: ReleasesComponent, canActivate: [AuthGuard] },
  { path: 'external-releases', component: ReleasesComponent, canActivate: [AuthGuard], data: { external: true } },
  { path: 'product-sheets', component: ProductSheetsComponent, canActivate: [AuthGuard]},
  { path: 'product-sheets/:id', component: EditSheetComponent},
  { path: 'releases/new', component: NewReleaseComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'releases/:id/edit', component: NewReleaseComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'tracks', component: TracksComponent, canActivate: [AuthGuard] },
  { path: 'tracks/new', component: NewTrackComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard]},
  { path: 'tracks/:id/edit', component: NewTrackComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard]},
  { path: 'videos', component: VideosComponent, canActivate: [AuthGuard] },
  { path: 'videos/new', component: NewVideoComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'videos/:id/edit', component: NewVideoComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'artists', component: ArtistsComponent, canActivate: [AuthGuard] },
  { path: 'artists/new', component: NewArtistComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'artists/:id/edit', component: NewArtistComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'assets', component: AssetsComponent, canActivate: [AuthGuard] },
  { path: 'assets/new', component: NewAssetComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'assets/:id/edit', component: NewAssetComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'users/new', component: NewUserComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
  { path: 'releases/:id/external/:token', component: ExternalReleaseComponent },
  { path: 'releases/:id/external', component: ExternalReleaseComponent },
  { path: 'login', component: LoginComponent },
  { path: 'invite/:token', component: SignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: '', redirectTo: 'projects', pathMatch: 'full'},
  { path: '**', redirectTo: 'projects' }
];
@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    ProjectsComponent,
    NewProjectComponent,
    ReleasesComponent,
    NewReleaseComponent,
    ExternalReleaseComponent,
    TracksComponent,
    NewTrackComponent,
    UpdateTrackComponent,
    ExternalTrackComponent,
    VideosComponent,
    NewVideoComponent,
    ArtistsComponent,
    NewArtistComponent,
    AssetsComponent,
    NewAssetComponent,
    FoldersComponent,
    NewFolderComponent,
    TerritoriesComponent,
    LoginComponent,
    SignupComponent,
    CatalogueComponent,
    SettingsComponent,
    UsersComponent,
    NewUserComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AccordionComponent,
    AccordionGroupComponent,
    ProductSheetsComponent,
    EditSheetComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgDatepickerModule,
    NgbPaginationModule.forRoot(),
    DragulaModule.forRoot(),
    OAuthModule.forRoot(),
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.circleSwish,
      backdropBackgroundColour: 'rgba(255, 255, 255, 0.7)',
      primaryColour: '#1c75bf',
      fullScreenBackdrop: true
  }),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 12,
          gap: 10,
        }
      }
    }),
    RouterModule.forRoot(appRoutes, {useHash: true}),
  ],
  providers: [Globals, AuthGuard, PendingChangesGuard, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
