import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Globals } from '../../globals';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { NotifierService } from 'angular-notifier';
import { HttpEventType } from '@angular/common/http';
import * as async from 'async';
import { Observable, forkJoin } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';

@Component({
  selector: 'app-new-release',
  templateUrl: './new-release.component.html',
  styleUrls: ['./new-release.component.css']
})
export class NewReleaseComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  release: any = {
    title: '',
    version: '',
    primaryArtistIds: [],
    featuringArtistIds: [],
    catNo: '',
    catNoNumber: 0,
    barcode: '',
    label: '',
    primaryGenre: '',
    secondaryGenre: '',
    releaseDate: null,
    originalReleaseDate: null,
    cYear: null,
    cLine: '',
    pYear: null,
    pLine: '',
    releaseType: '',
    format: '',
    configuration: '',
    carrierType: '',
    territories: [],
    tracks: [],
    assetIds: [],
    packshot: null,
    projectIds: [],
    packshot_url: '',
    type: '',
    physicalValidationErrors: [],
    digitalValidationErrors: [],
    performanceValidationErrors: [],
    notes: '',
    isSpecialPackaging: false,
    specialPackaging: '',
    dealerPrice: '',
    preOrderDate: null,
    rightsExpired: false,
    rightsExpiredDate: null,
    subAccountName: '',
    hfaExportManufacturer: '',
    hfaExporter: '',
    hfaUsPortOfEntry: '',
    hfaImportAgent: '',
    hfaImportQuantity: '',
    hfaDateOfEntry: null,
    hfaExportCountry: []
  };
  originalRelease = {};
  id: number;
  private sub: any;
  artists: [{}] = [{ _id: 'null', name: 'Loading..' }];
  projects: [{}] = [{ _id: 'null', name: 'Loading..' }];
  tracks = [];
  trackSearchText = "";
  tracksTotalPages;
  tracksCurrentPage;
  options: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 50,
    barTitleIfEmpty: 'Click to select a date'
  };
  settings: any = {
    configurations: [],
    packaging: []
  };
  discs = [];
  trackBeingAddedTo = 0;
  trackBeingAddedAtPosition = 0;
  assets = [];
  assetsTotalPages;
  assetsCurrentPage;
  addTag;
  packaging;
  encodeImage;
  selectedAssets = [];
  selectedAssetsOnInit = true;
  selectedAllAssets = false;
  hfaExportCountries;

  constructor(private route: ActivatedRoute, private router: Router, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalRelease = JSON.parse(JSON.stringify(this.release));

    this.hfaExportCountries = ['Worldwide', ...this.globals.territories];

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      this.http.get('/settings', {})
        .subscribe((settings) => {
          this.settings = settings;
          if (this.id)
            this.getRelease(this.id);
        });

      if (this.globals.dataPassed) {
        this.release = this.globals.dataPassed;
        delete this.release['_id'];
        delete this.release['catNo'];
        delete this.release['barcode'];
        this.globals.dataPassed = null;
      }

      this.getArtists();
      this.getProjects();
      this.getTracks(1);
      this.getAssets(1);
    })

  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.release) === JSON.stringify(this.originalRelease))
      return true;
    else
      return false;
  }

  getPackshot(id) {
    this.http.get('/assets/' + this.release['packshot'], 0)
      .subscribe((data) => {
        this.release['packshot_url'] = data['publicLink'];
        this.originalRelease = JSON.parse(JSON.stringify(this.release));
      });
  }

  deleteTrackFromDisc(volume, position) {

    for (var i = 0; i < this.release['tracks'].length; i++) {
      if (this.release['tracks'][i]['volume'] === volume && this.release['tracks'][i]['position'] === position) {
        this.release['tracks'].splice(i, 1);
      }
    }

    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (this.release['tracks'][i]['position'] > position)
        this.release['tracks'][i]['position'] = this.release['tracks'][i]['position'] - 1;
    }

    let deleteDisc = true;
    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (this.release['tracks'][i].volume === volume)
        deleteDisc = false;
    }

    if (deleteDisc)
      this.deleteDisc(volume);
    else
      this.sortTracks();
  }

  addTrackToDisc(track, volume, position) {

    track.volume = volume;
    track.position = position;
    track.trackId = track._id;

    this.release['tracks'].push(JSON.parse(JSON.stringify(track)));

    this.sortTracks();
  }

  sortTracks() {
    this.discs = [];
    let tracks = this.release['tracks'];

    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (this.search('volume', this.release['tracks'][i].volume, this.discs) === -1)
        this.discs.push({
          volume: this.release['tracks'][i]['volume'],
          tracks: []
        });

      this.discs[this.search('volume', this.release['tracks'][i].volume, this.discs)]['tracks'].push({
        title: this.release['tracks'][i]['title'],
        trackId: this.release['tracks'][i]['trackId'],
        position: this.release['tracks'][i]['position'],
        isrc: this.release['tracks'][i]['isrc'],
        version: this.release['tracks'][i]['version'],
        artists: this.getArtistsNames(this.release['tracks'][i])
      })
    }

    this.discs.sort(this.sortByVolume);

    for (let i = 0; i < this.discs.length; i++) {
      this.discs[i].tracks.sort(this.sortByPosition)
    }
  }

  sortByVolume(a, b) {
    if (a.volume < b.volume)
      return -1;
    if (a.volume > b.volume)
      return 1;
    return 0;
  }

  sortByPosition(a, b) {
    if (a.position < b.position)
      return -1;
    if (a.position > b.position)
      return 1;
    return 0;
  }

  addDisc() {
    this.discs.push({
      volume: this.discs.length + 1,
      tracks: []
    })
  }

  deleteDisc(volume) {
    let deletedAll = false;

    while (!deletedAll) {

      deletedAll = true;

      for (let i = 0; i < this.release['tracks'].length; i++) {
        if (this.release['tracks'][i]['volume'] === volume) {
          this.release['tracks'].splice(i, 1);
          deletedAll = false;
        }
      }
    }

    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (this.release['tracks'][i]['volume'] > volume)
        this.release['tracks'][i]['volume'] -= 1;
    }

    this.sortTracks();
  }

  getArtistsNames(track) {
    let artistsObjects = track['primaryArtistIds'].concat(track['featuringArtistIds']);
    let artists = '';

    for (let i = 0; i < artistsObjects.length; i++) {
      artists += artistsObjects[i].name + ', ';

    }

    artists = artists.substring(0, artists.length - 2);

    return artists;
  }

  stripTracksToSave() {
    let strippedTracks = [];
    for (let i = 0; i < this.release['tracks'].length; i++) {
      strippedTracks.push({
        volume: this.release['tracks'][i].volume,
        position: this.release['tracks'][i].position,
        trackId: this.release['tracks'][i].trackId
      })
    }

    return strippedTracks;
  }

  search(key, value, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return i;
      }
    }
    return -1;
  }

  getRelease(id) {
    this.http.get('/releases/' + id, 0)
      .subscribe((data) => {
        this.release = data;

        if (data['tracks'])
          this.sortTracks();

        if (data['packshot'])
          this.getPackshot(this.release['packshot']);

        if (!this.release.priceTier)
          this.release.priceTier = 'Front One';

        this.originalRelease = JSON.parse(JSON.stringify(data));
      });
  }
  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }

  getTracks(page) {
    let filter = {page : page};
    if(this.trackSearchText && this.trackSearchText != '')
      filter['text'] = this.trackSearchText;

    this.http.get('/tracks', filter)
      .subscribe((data) => {
        this.tracks = data['result'];
        this.tracksTotalPages = data['meta']['totalPages'];
        this.tracksCurrentPage = data['meta']['currentPage'];
      });
  }

  getProjects() {
    this.http.get('/projects', { limit: 1000 })
      .subscribe((data) => {
        this.projects = data['result'].map(project => {
          let artistName = project.artistIds && project.artistIds[0] ? project.artistIds[0].name : "";
          return { _id: project._id, name: project.title + " - " + artistName };
        });
      });
  }

  getAssets(page) {
    this.http.get('/assets', { page: page })
      .subscribe((data) => {
        this.assets = data['result'];
        this.assetsTotalPages = data['meta']['totalPages'];
        this.assetsCurrentPage = data['meta']['currentPage'];
      });
  }

  addAssetToRelease(asset) {
    this.release['assetIds'].push(asset);
  }

  addAssetToPackshot(asset) {
    this.http.get('/assets/' + asset._id, 0)
      .subscribe((data) => {
        console.log(data);
        this.release['packshot_url'] = data['publicLink']
        console.log(this.release['packshot_url']);
        this.release['packshot'] = data;
        console.log(this.release['packshot']);

      });
  }

  deleteAssetFromRelease(i) {

    this.release['assetIds'].splice(i, 1);
  }

  stripPrimaryArtists(callback) {
    let artistIds = [];

    async.each(this.release['primaryArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }

  stripFeaturingArtists(callback) {

    let artistIds = [];

    async.each(this.release['featuringArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }

  saveRelease(callback?) {
    this.sortTracksForSave();
    let release = JSON.parse(JSON.stringify(this.release));

    async.waterfall([
      callback => {
        this.stripPrimaryArtists((primaryArtists) => {
          release['primaryArtistIds'] = primaryArtists;
          callback(null, null);
        });
      },
      (arg, callback) => {
        this.stripFeaturingArtists((featuringArtists) => {
          release['featuringArtistIds'] = featuringArtists;
          callback(null, null);
        });
      }
    ], (err, result) => {
      release['tracks'] = this.stripTracksToSave();

      if (this.id) {
        this.http.put('/releases/' + this.id, release, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Updated Successfully');
            this.getRelease(this.id);
            this.getArtists();
            if (callback)
              callback();
          });
      } else {
        this.http.post('/releases', release, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Created Successfully');
            this.release = this.originalRelease;
            this.router.navigate(['releases/' + data['_id'] + '/edit']);
            if (callback)
              callback();
          });
      }
    });

  }

  assignCatNo() {
    console.log(this.release.projectIds);

    if (this.release.projectIds.length == 0) {
      return this.notifier.notify('error', 'Release should belong to a project');
    }

    if (!this.release.format) {
      return this.notifier.notify('error', 'Release format is required');
    }

    if (this.release.format == 'Physical' && !this.release.configuration) {
      return this.notifier.notify('error', 'Configuration is required for physical releases');
    }

    let controller = this;
    this.saveRelease(function () {
      controller.http.get('/catnos/assign/release/'+controller.id, {})
        .subscribe((data) => {
          controller.release['catNo'] = data;
        });
    });
  }

  assignBarcode() {
    if (!this.release.format) {
      return this.notifier.notify('error', 'Release format is required');
    }

    if (this.release.format == 'Physical' && !this.release.configuration) {
      return this.notifier.notify('error', 'Configuration is required for physical releases');
    }

    let controller = this;
    this.saveRelease(function () {
      controller.http.get('/barcodes/assign/' + controller.id, {})
        .subscribe((data) => {
          if (data['barcode']) {
            controller.release['barcode'] = data['barcode'];
            controller.notifier.notify('success', 'Barcode assigned Successfully');
          } else {
            controller.release['barcode'] = '';
          }
        }, error => {
          controller.notifier.notify('error', error['error']['error']);
        }
        );
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  delete() {
    this.http.delete('/releases/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['releases']);
      });

  }

  uploadPackshot(files: FileList) {
    console.log("uploadPackshot called");
    let file = files.item(0);

    this.http.post('/releases/' + this.id + '/add_packshot', { filename: this.stripFileName(file.name), type: file.type }, {})
      .subscribe((data) => {
        console.log(data);
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.id] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id] = 0;

            this.notifier.notify('success', 'File Uploaded Successfully');
            //Save packshot to release
            this.release['packshot'] = data['packshot'];
            this.http.put('/releases/' + this.id, this.release, {})
              .subscribe((dataReleases) => { });

            //save key to packshot
            this.http.get('/assets/' + this.release['packshot'], 0)
              .subscribe((dataAsset) => {
                let asset = dataAsset;
                console.log(asset);
                asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                this.http.put('/assets/' + this.release['packshot'], asset, {})
                  .subscribe((data) => {

                    this.getRelease(this.id);
                  });
              });
          }
        });
      });
  }

  uploadAsset(files: FileList) {
    this.globals.progressBars[this.id + '-assets'] = [];
    console.log("uploadAsset called");
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.globals.progressBars[this.id + '-assets'].push({
        name: file.name,
        progress: 0
      });

      this.http.post('/releases/' + this.id + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
        .subscribe((data) => {
          let formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[this.id + '-assets'][i].progress = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {

              this.globals.progressBars[this.id + '-assets'][i].progress = 0;

              this.notifier.notify('success', 'File Uploaded Successfully');
              // Save asset to release
              console.log(data);
              this.http.get('/assets/' + data['assetId'], 0)
                .subscribe((dataAsset) => {
                  let asset = dataAsset;
                  this.release['assetIds'].push(dataAsset);
                });
            }
          });
        });
    }

  }

  export() {
    this.http.post('/releases/export', { ids: [this.id] }, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Exported Successfully');
      });
  }

  copy() {
    this.globals.dataPassed = this.release;
    this.router.navigate(['./releases/new']);
  }

  deliver(...params: HTMLInputElement[]) {
    const deliveryPoints = [];
    const points = [
      "The Orchard",
      "PPL",
      "cvSync",
      "fuga",
      "Tencent",
      "tencentTakedown",
      "ILikeMusic",
      "Bandcamp",
      "Soundmouse",
    ];

    for (const i in params) {
      if (params[i].checked) deliveryPoints.push(points[i]);
      params[i].checked = false;
    }

    if (deliveryPoints.length > 0) {
      this.http
        .post("/releases/" + this.id + "/deliver", { deliveryPoints, release: this.id }, {})
        .subscribe((data) => {
          this.notifier.notify("success", "Delivered Successfully");
        });
    } else {
      this.notifier.notify("error", "No delivery points selected");
    }
  }

  send(emails: HTMLInputElement, format: HTMLInputElement, message: HTMLInputElement, includePackshot: HTMLInputElement, includeMetadata: HTMLInputElement, includeAudio: HTMLInputElement, downloadTimeLimit: HTMLInputElement) {
    let body = {
      emails: emails.value.split(/\r?\n/),
      format: format.value,
      message: message.value,
      includePackshot: includePackshot.checked,
      includeMetadata: includeMetadata.checked,
      includeAudio: includeAudio.checked,
      downloadTimeLimit: downloadTimeLimit.value,
      assetIds: this.selectedAssets
    }
    this.http.post('/releases/' + this.id + '/send', body, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Sent Successfully');
      });
  }

  editTrack(id) {
    this.router.navigate(['tracks/' + id + '/edit'], { queryParams: { releaseId: this.release._id } });
  }

  uploadToTrack(trackId, files: FileList, singleFile?) {
    let file;
    if (!singleFile)
      file = files.item(0);
    else
      file = singleFile;

    this.http.post('/tracks/' + trackId + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[trackId] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {


            this.globals.progressBars[trackId] = 0;

            this.notifier.notify('success', 'File Uploaded Successfully');
            //Save packshot to release
            this.http.get('/tracks/' + trackId, {}).subscribe((track) => {
              track['assetIds'].push(data['assetId']);
              this.http.put('/tracks/' + trackId, track, {})
                .subscribe((dataReleases) => { });

              //save key
              this.http.get('/assets/' + data['assetId'], 0)
                .subscribe((dataAsset) => {
                  let asset = dataAsset;
                  asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                  this.http.put('/assets/' + data['assetId'], asset, {})
                    .subscribe((data) => {

                    });
                });
            });
          }
        });
      });
  }

  uploadMultipleAudioFiles(files: FileList, disc) {
    let filenames = [];
    let reference = this;

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      filenames.push({ index: i, filename: file.name.substring(0, file.name.indexOf('.')) });
    }

    async.each(filenames, function (file, callback) {
      reference.http.post('/tracks', {
        title: file.filename,
        primaryArtistIds: reference.release['primaryArtistIds'],
        featuringArtistIds: reference.release['primaryArtistIds'],
        projectIds: reference.release['projectIds'],
        pYear: reference.release['pYear'],
        pLine: reference.release['pLine'],
        primaryGenre: reference.release['primaryGenre'],
        secondaryGenre: reference.release['secondaryGenre'],
        territories: reference.release['territories']
      }, {})
        .subscribe((data) => {
          file.track = data;
          callback();
        });
    }, function (err) {
      //tracks created => reload all tracks, add tracks to disk and start upload.
      for (let i = 0; i < filenames.length; i++) {
        const file = filenames[i];
        reference.addTrackToDisc(file.track, disc.volume, disc.tracks.length + 1);
      }

      reference.saveRelease(function () {
        for (let i = 0; i < filenames.length; i++) {
          const file = filenames[i];
          reference.uploadToTrack(file.track._id, null, files.item(file.index));
        }
      });

    });
  }

  trackHasAsset(id) {
    for (let i = 0; i < this.release['tracks'].length; i++) {
      const track = this.release['tracks'][i];
      if (track._id === id) {
        if (track.assetIds && track.assetIds.length !== 0)
          return true;
        else
          return false;
      }
    }
  }

  clear(field) {
    this.release[field] = null;
  }

  isAssetSelected(id) {
    if (this.selectedAssetsOnInit)
      return true;

    this.selectedAssetsOnInit = false;

    if (this.selectedAssets.indexOf(id) > -1)
      return true;
    else
      return false;
  }

  addToSelectedAssets(id) {
    if (this.selectedAssets.indexOf(id) > -1) {
      this.selectedAssets.splice(this.selectedAssets.indexOf(id), 1);
    }
    else {
      this.selectedAssets.push(id);
    }
  }

  stripFileName(name) {
    name = name.replace('&', '');

    return name;
  }

  sortTracksForSave() {
    for (let i = 0; i < this.discs.length; i++) {
      const disc = this.discs[i];
      const volume = disc.volume;

      for (let j = 0; j < disc.tracks.length; j++) {
        const track = disc.tracks[j];
        for (let z = 0; z < this.release.tracks.length; z++) {
          const releaseTrack = this.release.tracks[z];
          if (releaseTrack._id == track.trackId && releaseTrack.volume == volume) {
            releaseTrack.position = j + 1;
          }
        }
      }
    }
  }

  // searchTracks() {
  //   console.log("Searching Tracks");
  //   this.http.get('/tracks', { text: this.trackSearchText })
  //     .subscribe((data) => {
  //       this.tracks = data['result'];
  //       this.tracksTotalPages = data['meta']['totalPages'];
  //       this.tracksCurrentPage = data['meta']['currentPage'];
  //     });
  // }

  saveAllTrack(callback?) {
    // used for addToAllTracks (Composer and Perfomers)
    const tracks = this.release['tracks'];
    const promises = tracks.map((track, index) => {
      if (track._id) {
        return this.http.put('/tracks/' + track._id, track, {});
      }
    });

    forkJoin(promises).subscribe(results => {
      this.getRelease(this.id);
      if(callback) {
        callback();
      }
    });
  }

  addToAllTracks(track) {

    for (let i = 0; i < this.release['tracks'].length; i++) {

      if (track.pYear) {
        this.release['tracks'][i]['pYear'] = track.pYear;
      }

      if (track.pLine) {
        this.release['tracks'][i]['pLine'] = track.pLine;
      }

      if (track.producer) {
        this.release['tracks'][i]['producer'] = track.producer;
      }

      if(track.publishingInfo.length > 0) {
        this.release['tracks'][i]['publishingInfo'].push(...track.publishingInfo);
      }

      if(track.performers.length > 0) {
        this.release['tracks'][i]['performers'].push(...track.performers);
      }
    }

    this.saveAllTrack(() =>  {
      this.originalRelease = JSON.parse(JSON.stringify(this.release));
      this.notifier.notify('success', 'Information added to all tracks.');
    });
  }
}
