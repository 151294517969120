import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Globals } from '../../globals';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-track',
  templateUrl: './update-track.component.html',
  styleUrls: ['./new-track.component.css']
})
export class UpdateTrackComponent implements OnInit, ComponentCanDeactivate {
  @Input() fromModal;
  @Output() closeModal = new EventEmitter();


  track: any = {
    pYear: 0,
    pLine: '',
    producer: '',
    performers: [],
    publishingInfo: []
  };

  originalTrack = {};

  constructor(private router: Router, private route: ActivatedRoute, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalTrack = JSON.parse(JSON.stringify(this.track));
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.track) === JSON.stringify(this.originalTrack))
      return true;

    return false;
  }

  addPublishingInfo() {
    this.track.publishingInfo.push({});
  }

  deletePublishingInfo(i) {
    this.track.publishingInfo.splice(i, 1);
  }

  addPerformer() {
    this.track.performers.push({ id: new Date(), name: '', performerType: '', role: '' });
  }

  deletePerformer(i) {
    this.track.performers.splice(i, 1);
  }

  addToAllTracks() {
    this.closeModal.emit(this.track);
  }
}
