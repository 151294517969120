import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import {Globals} from '../../globals';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatepickerOptions} from 'ng2-datepicker';
import {NotifierService} from 'angular-notifier';
import {HttpEventType} from '@angular/common/http';
import * as async from 'async';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-external-release',
  templateUrl: './external-release.component.html',
  styleUrls: ['./external-release.component.css']
})
export class ExternalReleaseComponent implements OnInit, OnDestroy {
  release: any = {
    title: '',
    version: '',
    primaryArtistIds: [],
    featuringArtistIds: [],
    catNo: '',
    catNoNumber: 0,
    barcode: '',
    label: '',
    primaryGenre: '',
    secondaryGenre: '',
    releaseDate: null,
    originalReleaseDate: null,
    cYear: null,
    cLine: '',
    pYear: null,
    pLine: '',
    releaseType: '',
    format: '',
    configuration: '',
    territories: [],
    tracks: [],
    assetIds: [],
    packshot: null,
    projectIds: [],
    packshot_url: '',
    type: '',
    physicalValidationErrors: [],
    digitalValidationErrors: [],
    performanceValidationErrors: [],
    notes: '',
    isSpecialPackaging: false,
    specialPackaging: '',
    dealerPrice: '',
    preOrderDate: null,
    rightsExpired: false,
    rightsExpiredDate: null
  };
  originalRelease = {};
  id: number;
  private sub: any;
  artists: [{}];
  options: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 50,
    barTitleIfEmpty: 'Click to select a date'
  };
  settings: any = {
    configurations: [],
    packaging: []
  };
  discs = [];
  addTag;
  packaging;
  encodeImage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private http: ApiService,
    public globals: Globals) { }

  ngOnInit() {
    this.globals.currentPage = 'External Release';
    this.globals.spinnerLoading = false;
    this.originalRelease = JSON.parse(JSON.stringify(this.release));

    this.sub = this.route.params.subscribe(params => {

      localStorage.setItem('tokenLinkedId', params['id']);

      if (params['token']) {
        localStorage.setItem('externalToken', params['token']);
        location.href = location.origin.concat('/#/releases/' + params['id'] + '/external');
      }

      this.id = params['id'];

      this.http.get('/settings', {})
        .subscribe((settings) => {
          this.settings = settings;
          if (this.id) {
            this.getRelease(this.id);
          }
        });

      // Load Artists
      this.getArtists();

    });
  }

  ngOnDestroy() {
    this.sub = [];
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.release) === JSON.stringify(this.originalRelease)) {
      return true;
    } else {
      return false;
    }
  }


  getRelease(id) {
    this.http.get('/releases/' + id, 0)
      .subscribe((data) => {
        this.release = data;

        if (data['tracks']) {
          this.sortTracks();
        }
        if (this.discs.length <= 0) {
          this.addDisc();
        }
        if (data['packshot']) {
          this.getPackshot(this.release['packshot']);
        }
        if (!this.release.priceTier) {
          this.release.priceTier = 'Front One';
        }
        this.originalRelease = JSON.parse(JSON.stringify(data));
      });
  }

  saveRelease(finalize, callback?) {

    this.sortTracksForSave();
    const release = JSON.parse(JSON.stringify(this.release));
    async.waterfall([
      (callback) => {
        this.stripPrimaryArtists((primaryArtists) => {
          release['primaryArtistIds'] = primaryArtists;
          callback(null, null);
        });
      },
      (arg, callback) => {
        this.stripFeaturingArtists((featuringArtists) => {
          release['featuringArtistIds'] = featuringArtists;
          callback(null, null);
        });
      }
    ], (err, result) => {
      release['tracks'] = this.stripTracksToSave();
      release['finalize'] = finalize;

      if (this.id) {
        this.http.put('/releases/' + this.id, release, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Updated Successfully');
            this.getRelease(this.id);
            // this.getArtists();
            if (callback)
              callback();
          });
      }
    });

  }

  uploadPackshot(files: FileList) {
    const file = files.item(0);

    this.http.post('/releases/' + this.id + '/add_packshot', { filename: this.stripFileName(file.name), type: file.type }, {})
      .subscribe((data) => {
        console.log(data);
        const formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.id] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id] = 0;
            this.notifier.notify('success', 'File Uploaded Successfully');
            this.release['packshot'] = data['packshot'];
            this.saveRelease(false);

            // save key to packshot
            this.http.get('/assets/' + this.release['packshot'], 0)
              .subscribe((dataAsset) => {
                const asset = dataAsset;
                asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body)
                  .indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));
                this.http.put('/assets/' + this.release['packshot'], asset, {})
                  .subscribe((res) => {
                    this.getRelease(this.id);
                  });
              });
          }
        });
      });
  }

  getPackshot(id) {
    this.http.get('/assets/' + this.release['packshot'], 0)
      .subscribe((data) => {
        this.release['packshot_url'] = data['publicLink'];
        this.originalRelease = JSON.parse(JSON.stringify(this.release));
      });
  }

// ---------------------------------------------------------
//                    Disk Functions
// ----------------------------------------------------------

  addDisc() {
    this.discs.push({
      volume: this.discs.length + 1,
      tracks: []
    });
  }

  sortTracks() {
    this.discs = [];
    const tracks = this.release['tracks'];

    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (this.search('volume', this.release['tracks'][i].volume, this.discs) === -1) {
        this.discs.push({
          volume: this.release['tracks'][i]['volume'],
          tracks: []
        });
      }
      this.discs[this.search('volume', this.release['tracks'][i].volume, this.discs)]['tracks'].push({
        title: this.release['tracks'][i]['title'],
        trackId: this.release['tracks'][i]['trackId'],
        position: this.release['tracks'][i]['position'],
        isrc: this.release['tracks'][i]['isrc'],
        version: this.release['tracks'][i]['version'],
        artists: this.getArtistsNames(this.release['tracks'][i])
      });
    }

    this.discs.sort(this.sortByVolume);

    for (let i = 0; i < this.discs.length; i++) {
      this.discs[i].tracks.sort(this.sortByPosition);
    }
  }

  sortByVolume(a, b) {
    if (a.volume < b.volume) {
      return -1;
    }
    if (a.volume > b.volume) {
      return 1;
    }
    return 0;
  }

  sortByPosition(a, b) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

// ---------------------------------------------------------
//                    Track Functions
// ----------------------------------------------------------

  newTrack() {
    return {
      type: 'Track',
      title: 'Add New Track',
      version: '',
      primaryArtistIds: this.release.primaryArtistIds ? this.release.primaryArtistIds : [],
      featuringArtistIds: this.release.featuringArtistIds ? this.release.featuringArtistIds : [],
      isrc: '',
      isrcYear: 0,
      isrcNumber: 0,
      primaryGenre: this.release.primaryGenre ? this.release.primaryGenre : '',
      secondaryGenre: this.release.secondaryGenre ? this.release.secondaryGenre : '',
      pYear: this.release.pYear ? this.release.pYear : 0,
      pLine: this.release.pLine ? this.release.pLine : '',
      composers: [],
      publishers: '',
      territories: this.release.territories ? this.release.territories : [],
      rightsEndDate: null,
      initialProducer: '',
      lineUpComplete: false,
      performers: [],
      assetIds: [],
      projectIds: [],
      lyrics: '',
      exclusiveFor: null,
      exclusiveUntil: null,
      publishingInfo: [],
      rightsHolderCompanyName: 'Cooking Vinyl Limited',
      rightsHolderRightsType: 'Exclusive Licensee',
      rightsStartDate: null,
      recordingDate: null,
      countryOfRecording: 'GB',
      countryOfCommission: 'GB',
      initialProducerTerritory: 'GB',
      volume: 1,
      position: 0,
      trackId: ''
    };
  }

  addTrack(event?) {
    let track = null;
    if (event) {
      if(event.track){
        track = event.track;
      } else {
        track = Object.assign({}, this.release['tracks'][event.index]);
      }
      track.title = track.title + ' (Duplicate)';
    } else {
      track = this.newTrack();
    }

    if (track._id)
      delete track._id;

    const position = this.release['tracks'].length;
    this.release['tracks'][position] = JSON.parse(JSON.stringify(track));
    this.saveTrack(position);
  }

  saveTrack(index) {
    const track = this.release['tracks'][index];
    if (track._id) {
      this.http.put('/tracks/' + track._id, track, {})
        .subscribe((data) => {
          this.addTrackToDisc(data, 1, index);
        });
    } else {
      this.http.post('/tracks', track, {})
        .subscribe((data) => {
          this.http.put('/tracks/' + data['_id'], data, {})
            .subscribe((res) => {
              this.addTrackToDisc(res, 1, index);
            });
        });
    }
  }

  saveAllTrack() {
    // used for addToAllTracks (Composer and Perfomers)
    const tracks = this.release['tracks'];
    const promises = tracks.map((track, index) => {
      if (track._id) {
        return this.http.put('/tracks/' + track._id, track, {})
          .subscribe((data) => {
            this.release['tracks'][index] = JSON.parse(JSON.stringify(data));
          });
      }
    });
  }

  addTrackToDisc(track, volume, position) {
    track.volume = volume;
    track.position = position;
    track.trackId = track._id;
    this.release['tracks'][position] = JSON.parse(JSON.stringify(track));
    this.sortTracks();
    this.saveRelease(false);
  }

  sortTracksForSave() {
    for (let i = 0; i < this.discs.length; i++) {
      const disc = this.discs[i];
      const volume = disc.volume;

      for (let j = 0; j < disc.tracks.length; j++) {
        const track = disc.tracks[j];
        for (let z = 0; z < this.release.tracks.length; z++) {
          const releaseTrack = this.release.tracks[z];
          if (releaseTrack._id === track.trackId && releaseTrack.volume === volume) {
            releaseTrack.position = j + 1;
          }
        }
      }
    }
  }

  stripTracksToSave() {
    const strippedTracks = [];
    for (let i = 0; i < this.release['tracks'].length; i++) {
      strippedTracks.push({
        volume: this.release['tracks'][i].volume,
        position: this.release['tracks'][i].position,
        trackId: this.release['tracks'][i].trackId
      });
    }
    return strippedTracks;
  }

  addPerformerToAll(event) {
    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (event.skip !== i) {
        this.release['tracks'][i]['performers'].push(event.performer);
      }
    }
    this.saveAllTrack();
  }

  addPublisherToAll(event) {
    for (let i = 0; i < this.release['tracks'].length; i++) {
      if (event.skip !== i) {
        this.release['tracks'][i]['publishingInfo'].push(event.publisher);
      }
    }
    this.saveAllTrack();
  }

  deleteTrack(event) {
    if (event.index) {
      const trackId = this.release['tracks'][event.index]['_id'];
      this.release['tracks'].splice(event.index, 1);
      this.http.delete('/tracks/' + trackId, {})
        .subscribe((data) => {
          this.notifier.notify('success', 'Track Deleted Successfully');
        });
    }
  }


// ---------------------------------------------------------
//                    Artists Functions
// ----------------------------------------------------------

  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }

  stripPrimaryArtists(callback) {
    const artistIds = [];
    async.each(this.release['primaryArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      } else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    });
  }

  stripFeaturingArtists(callback) {
    const artistIds = [];
    async.each(this.release['featuringArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      } else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    });
  }

  getArtistsNames(track) {
    const artistsObjects = track['primaryArtistIds'].concat(track['featuringArtistIds']);
    let artists = '';

    for (let i = 0; i < artistsObjects.length; i++) {
      artists += artistsObjects[i].name + ', ';

    }

    artists = artists.substring(0, artists.length - 2);

    return artists;
  }

// ---------------------------------------------------------
//                    Other Functions
// ----------------------------------------------------------

  clear(field) {
    this.release[field] = null;
  }

  search(key, value, array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return i;
      }
    }
    return -1;
  }

  stripFileName(name) {
    name = name.replace('&', '');

    return name;
  }

}
