import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Globals } from '../../globals';
import { DatepickerOptions } from 'ng2-datepicker';
import { NotifierService } from 'angular-notifier';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';
import * as async from 'async';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.css']
})
export class NewProjectComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  project: any = {
    title: '',
    artistIds: [],
    projectManager: '',
    catNo: '',
    artistCatNo: '',
    description: '',
    licensees: []
  };
  originalProject = {};
  id: number;
  private sub: any;
  territories: {}[] = [];
  selectedTerritory: string = 'Not selected';
  date: Date;
  artists: [{}] = [{ _id: 'null', name: 'Loading..' }];
  addTag;
  options: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 1,
    barTitleIfEmpty: 'Click to select a date',
  };

  constructor(private router: Router, private route: ActivatedRoute, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalProject = JSON.parse(JSON.stringify(this.project));
    this.territories = this.globals.territories;

    this.sub = this.route.params.subscribe(params => {
      this.date = new Date;
      this.id = params['id'];

      if (this.id)
        this.getProject(this.id);

      this.getArtists();
    })
  }

  getProject(id) {
    this.http.get('/projects/' + id, 0)
      .subscribe((data) => {
        this.project = data;
        this.originalProject = JSON.parse(JSON.stringify(data));
      });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.project) === JSON.stringify(this.originalProject))
      return true;
    else
      return false;
  }

  saveProject() {
    this.stripArtists((artists) => {
      this.project['artistIds'] = artists;
      if (this.id) {
        this.http.put('/projects/' + this.id, this.project, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Updated Successfully');
            this.getProject(this.id);
            this.getArtists();
          });
      } else {
        this.http.post('/projects', this.project, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Created Successfully');
            this.project = this.originalProject;
            this.router.navigate(['projects/'+data['_id']+'/edit']);
          });
      }
    });
  }

  stripArtists(callback) {
    let artistIds = [];

    async.each(this.project['artistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }


  assignCatNo() {
    this.http.get('/catnos/assign', {})
      .subscribe((data) => {
        this.project['catNo'] = data;
      });
  }

  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }

  addLicensee() {
    this.project['licensees'].push({ id: new Date(), territory: '', licensee: '', startDate: new Date, endDate: new Date() });
  }

  deleteLicensee(i) {
    this.project['licensees'].splice(i, 1);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  delete() {
    this.http.delete('/projects/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['projects']);
      });

  }
  generateRandom() {
    return Math.random();
  }
}
