import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.css']
})
export class ReleasesComponent implements OnInit {
  releases: {}[] = [];
  totalPages = 0;
  currentPage = 1;
  singleItemToDelete = -1;

  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle = '';
  searchText = '';
  searchFormat = '';
  searchLabel = '';

  external = false;
  externalEmail = '';
  newExternalReleaseLink = 'You can copy link, once created';
  externalLink = null;
  externalTitle = null;
  externalId = null;

  dpErrors = {
    theOrchard: 0,
    ppl: 0,
    cvSync: 0,
    fuga: 0,
    tencent: 0,
    bandcamp: 0,
    ilikemusic: 0,
    soundmouse: 0,
  }

  hfStartMonth = null;
  hfStartYear = null;
  hfLastUsedId = null;
  hfCatNos = null;

  constructor(private notifier: NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http: ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Releases';

    if (this.route.data[`value`][`external`] === true) {
      this.external = true;
    }


    this.getReleases(1);
  }

  getReleases(page) {
    let filter = { page: page };

    if (this.searchText && this.searchText != '')
      filter['text'] = this.searchText;

    if (this.searchFormat && this.searchFormat != '')
      filter['format'] = this.searchFormat;

    if (this.searchLabel && this.searchLabel != '')
      filter['label'] = this.searchLabel;


    filter['external'] = this.external;


    this.http.get('/releases', filter)
      .subscribe((data) => {
        this.releases = data['result'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
        this.getArtistNames();

      });
  }

  editRelease(id) {
    this.router.navigateByUrl('/releases/' + id + '/edit');
  }

  onCreateRelease() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  deleteSingleItem(i) {
    this.http.delete('/releases/' + this.releases[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getReleases(this.currentPage);
      });
  }

  isSelected(id) {
    if (this.selectedItemsOnInit)
      return true;

    this.selectedItemsOnInit = false;

    if (this.selectedItems.indexOf(id) > -1)
      return true;
    else
      return false;
  }

  addToSelectedItems(id) {
    const release: any = this.releases.find((r: any) => r._id === id);
    const invalids = {
      theOrchard:
        !release.format ||
        release.format == "" ||
        (release.format == "Physical" &&
          release.physicalValidationErrors.length != 0) ||
        (release.format == "Digital" &&
          release.digitalValidationErrors.length != 0),
      ppl: 
        release.performanceValidationErrors.length != 0 ||
        (release.format == "Physical" && !release.carrierType),
      cvSync: release.digitalValidationErrors.length != 0,
      fuga: release.digitalValidationErrors.length != 0,
      tencent: release.digitalValidationErrors.length != 0,
      tencentTakedown: release.digitalValidationErrors.length != 0,
      bandcamp: release.digitalValidationErrors.length != 0,
      ilikemusic: release.digitalValidationErrors.length != 0,
      soundmouse: release.digitalValidationErrors.length != 0
    };
    let weight = 1;
    if (this.selectedItems.indexOf(id) > -1) {
      weight = -1;
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    }
    else {
      this.selectedItems.push(id);
    }
    Object.keys(invalids).forEach(k => {
      if (invalids[k]) {
        this.dpErrors[k] += weight;
      }
    })
  }

  deleteMultipleItems() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/releases/' + this.selectedItems[i], {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Deleted Successfully');
          this.getReleases(this.currentPage);
        });
    }
  }

  getArtistNames() {
    for (let i = 0; i < this.releases.length; i++) {
      let artists = '';

      let element = this.releases[i];

      for (let j = 0; j < element['primaryArtistIds'].length; j++) {
        const artist = element['primaryArtistIds'][j];
        artists += artist['name'] + ', ';
      }

      for (let j = 0; j < element['featuringArtistIds'].length; j++) {
        const artist = element['featuringArtistIds'][j];
        artists += artist['name'] + ', ';
      }

      element['artistIds'] = artists;
      element['artistIds'] = element['artistIds'].substring(0, element['artistIds'].length - 2)
    }
  }

  export() {
    this.http.post('/releases/export', { ids: this.selectedItems }, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Exported Successfully');
      });
  }

  harryFoxExport() {
    if (!this.hfLastUsedId) { return this.notifier.notify('error', 'Harry Fox last used ID is required'); }
    if (this.hfLastUsedId != parseInt(this.hfLastUsedId)) { return this.notifier.notify('error', 'Harry Fox last used ID is required to be a number'); }
    if (!this.hfStartMonth) { return this.notifier.notify('error', 'Export month is required'); }
    if (!this.hfStartYear) { return this.notifier.notify('error', 'Export year is required'); }

    const body = { lastUsedId: this.hfLastUsedId, year: this.hfStartYear, month: this.hfStartMonth, catNos: this.hfCatNos };

    this.http.post('/releases/harry_fox_export_digital', body, {})
      .subscribe(() => {
        delete this.hfLastUsedId;
        delete this.hfStartMonth;
        delete this.hfStartYear;
        delete this.hfCatNos;
        this.notifier.notify('success', 'Exported Successfully');
      });

  }

  harryFoxExportPhysical() {
    if (!this.hfLastUsedId) { return this.notifier.notify('error', 'Harry Fox last used ID is required'); }
    if (this.hfLastUsedId != parseInt(this.hfLastUsedId)) { return this.notifier.notify('error', 'Harry Fox last used ID is required to be a number'); }
    if (!this.hfStartMonth) { return this.notifier.notify('error', 'Export month is required'); }
    if (!this.hfStartYear) { return this.notifier.notify('error', 'Export year is required'); }

    const body = { lastUsedId: this.hfLastUsedId, year: this.hfStartYear, month: this.hfStartMonth, catNos: this.hfCatNos };

    this.http.post('/releases/harry_fox_export_physical', body, {})
      .subscribe(() => {
        delete this.hfLastUsedId;
        delete this.hfStartMonth;
        delete this.hfStartYear;
        delete this.hfCatNos;
        this.notifier.notify('success', 'Exported Successfully');
      });

  }
  harryFoxImport(files: FileList) {

    const formData: FormData = new FormData();
    formData.append('file', files[0], files[0].name);

    const progress = new Subject<number>();

    this.http.postWithProgress('/releases/hfaImport', formData)
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          console.log(percentDone);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      });
  }

  import(files: FileList) {

    const formData: FormData = new FormData();
    formData.append('file', files[0], files[0].name);

    const progress = new Subject<number>();

    this.http.postWithProgress('/releases/import', formData)
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          console.log(percentDone);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      });
  }

  deliver(...params: HTMLInputElement[]) {
    const deliveryPoints = [];
    const points = [
      "The Orchard",
      "PPL",
      "cvSync",
      "fuga",
      "Tencent",
      "tencentTakedown",
      "ILikeMusic",
      "Bandcamp",
      "Soundmouse",
    ];

    for (const i in params) {
      if (params[i].checked) deliveryPoints.push(points[i]);
      params[i].checked = false;
    }

    if (deliveryPoints.length > 0) {
      this.http
        .post(
          "/releases/deliver",
          { deliveryPoints, releases: this.selectedItems },
          {}
        )
        .subscribe((data) => {
          this.notifier.notify("success", "Delivered Successfully");
        });
    } else {
      this.notifier.notify("error", "No delivery points selected");
    }
  }

  createExternalRelease() {
    this.http.post('/releases/external_release', { email: this.externalEmail }, {})
      .subscribe((data) => {
        this.newExternalReleaseLink = data['link'];
        this.notifier.notify('success', 'Created Successfully');
      });
  }

  setExternalReleaseLink(id, title, externalMeta) {
    this.externalLink = 'http://ec2-34-253-75-22.eu-west-1.compute.amazonaws.com/#/releases/' + id + '/external/' + externalMeta.token;
    this.externalTitle = title;
    this.externalId = id;
  }

  approveMultipleItems() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.put('/releases/' + this.selectedItems[i] + '/approve', { approved: true }, {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Approved Successfully');
          this.getReleases(this.currentPage);
        });
    }
  }

  getTotalCatNos() {
    if (!this.hfCatNos) return 0;
    return this.hfCatNos.split('\n').filter((catNo) => catNo && catNo.length !== 0).length
  }
}
