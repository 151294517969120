import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as jwt from 'angular2-jwt-simple';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private http: ApiService, private globals: Globals, private router: Router, private oauthService: OAuthService) { }

  authConfig: AuthConfig;

  user: any = {
    email: '',
    password: ''
  }

  error = '';

  ngOnInit() {

    this.checkForGoogleLogin();

    this.globals.currentPage = 'Login'

    //Initialize google auth
    this.authConfig = {
      issuer: 'https://accounts.google.com',
      redirectUri: window.location.origin + '/login',
      clientId: this.globals.googleClientId,
      scope: 'openid profile email',
      strictDiscoveryDocumentValidation: false
    };

    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  login() {
    this.http.post('/users/signin', this.user, {})
      .subscribe((data) => {
        localStorage.setItem('token', data['token']);
        this.router.navigate(['projects']);
      }, (err => {
        this.error = 'Wrong Email/Password';
      }));
  }

  signup() {
    this.router.navigate(['signup']);
  }
  
  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  googleLogin() {
    this.oauthService.initImplicitFlow();
  }

  checkForGoogleLogin() {
    let url = this.router.url;
    let token;

    if (url.indexOf('id_token') != -1) {
      url = url.substring(url.indexOf('id_token') + 9, url.length);
      token = url.substring(0, url.indexOf('&'));
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const email = decodedToken.email;
      const encodedEmail = jwt.encode({email : email}, 'ReX1R1zpaoZA6uDy2phUB4A7tCk');

      this.http.post('/users/google_signin', {payload: encodedEmail}, {})
        .subscribe((data) => {
          localStorage.setItem('token', data['token']);
          this.router.navigate(['projects']);
        }, (err => {
          this.error = 'This email address isn\'t associated with any user.';
        }));
    }
  }
}
