import { tap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { Globals } from "../globals";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private globals: Globals, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.globals.spinnerLoading = true;
        this.globals.requests.push(request.url);

        const token = localStorage.getItem('token');
        if (token) {
            const headers = new HttpHeaders({
                'access_token': token
            });
            request = request.clone({ headers });
        } else {
            const externalToken = localStorage.getItem('externalToken');
            const tokenLinkedId = localStorage.getItem('tokenLinkedId');
            if (externalToken && tokenLinkedId) {

                const headers = new HttpHeaders({
                    'access_token': externalToken,
                    'linked_id': tokenLinkedId
                });

                request = request.clone({ headers });
            }
        }

        return next.handle(request).pipe(tap(
            success => {
                setTimeout(() => {
                    if (success.type == 4) {
                        this.globals.requests.splice(this.globals.requests.indexOf(request.url), 1);
                    }

                    if (this.globals.requests.length == 0)
                        this.globals.spinnerLoading = false;
                }, 300);
            },
            err => {
                setTimeout(() => {
                    this.globals.requests.splice(this.globals.requests.indexOf(request.url), 1);
                    if (this.globals.requests.length == 0)
                        this.globals.spinnerLoading = false;

                }, 300);
                if (err.status == 401) {
                    if (this.router.url.indexOf('/product-sheets/') !== 0) {
                        localStorage.clear();
                        this.router.navigate(['/login']);
                    }
                }
            }
        ))
    }
}
