import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Globals } from '../../globals';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-artist',
  templateUrl: './new-artist.component.html',
  styleUrls: ['./new-artist.component.css']
})
export class NewArtistComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  artist: any = {
    name: '',
    biog: ''
  };
  originalArtist = {};
  id: number;
  private sub: any;

  constructor(private router: Router, private route: ActivatedRoute, private http: ApiService, public globals: Globals, private notifier: NotifierService) { }

  ngOnInit() {
    this.originalArtist = JSON.parse(JSON.stringify(this.artist));
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id)
        this.getArtist(this.id);
    })
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.artist) === JSON.stringify(this.originalArtist))
      return true;
    else
      return false;
  }

  getArtist(id) {
    this.http.get('/artists/' + id, 0)
      .subscribe((data) => {
        this.artist = data;
        this.originalArtist = JSON.parse(JSON.stringify(data));
      });
  }

  saveArtist() {
    if (this.id) {
      this.http.put('/artists/' + this.id, this.artist, {})
        .subscribe((data) => {
          console.log('Updated.')
          this.notifier.notify('success', 'Updated Successfully');
        });
    } else {
      this.http.post('/artists', this.artist, {})
        .subscribe((data) => {
          this.notifier.notify('success', 'Created Successfully');
          this.artist = this.originalArtist;
          this.router.navigate(['artists/' + data['_id'] + '/edit']);
        });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  delete() {
    this.http.delete('/artists/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['artists']);
      });

  }

}
