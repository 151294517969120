import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-product-sheets',
  templateUrl: './sheets.component.html',
  styleUrls: ['./sheets.component.css']
})
export class ProductSheetsComponent implements OnInit {
  sheets: {}[] = [];
  totalPages = 0;
  currentPage = 1;
  singleItemToDelete = -1;

  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle = '';
  searchText = '';
  searchFormat = '';
  searchLabel = '';

  external = false;
  externalEmail = '';
  newExternalReleaseLink = 'Loading...';
  externalLink = null;
  externalTitle = null;
  externalId = null;

  constructor(private notifier: NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http: ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Product Sheets';

    this.getProductSheets(1);
  }

  getProductSheets(page) {
    let filter = { page: page };

    if (this.searchText && this.searchText != '')
      filter['text'] = this.searchText;

    if (this.searchFormat && this.searchFormat != '')
      filter['format'] = this.searchFormat;

    if (this.searchLabel && this.searchLabel != '')
      filter['label'] = this.searchLabel;

    this.http.get('/product-sheets', filter)
      .subscribe((data) => {
        this.sheets = data['result'];
        this.totalPages = data['meta']['totalPages'];
        this.currentPage = data['meta']['currentPage'];
      });
  }

  isSelected(id) {
    if (this.selectedItemsOnInit)
      return true;

    this.selectedItemsOnInit = false;

    if (this.selectedItems.indexOf(id) > -1)
      return true;
    else
      return false;
  }



  addToSelectedItems(id) {
    if (this.selectedItems.indexOf(id) > -1) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    }
    else {
      this.selectedItems.push(id);
    }
  }
  
  create() {
    this.newExternalReleaseLink = 'Loading...';
    this.http.post('/product-sheets', {}, {})
      .subscribe((data) => {
        this.updateExternalReleaseLink(data['id']);
        this.notifier.notify('success', 'Created Successfully');
      });
  }

  updateExternalReleaseLink(id) {
    this.newExternalReleaseLink = 'https://repertoire.cookingvinyl.com/#/product-sheets/' + id;
  }

  approveMultipleItems() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.put('/releases/' + this.selectedItems[i] + '/approve', { approved: true }, {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Approved Successfully');
          this.getProductSheets(this.currentPage);
        });
    }
  }

  approve() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.put('/product-sheets/' + this.selectedItems[i] + '/approve', {}, {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Approved Successfully');
          this.getProductSheets(this.currentPage);
        }, (error) => {
          console.log(error)
          this.notifier.notify('error', error.error.error);
        });
    }
  }
  
  unlock() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.put('/product-sheets/' + this.selectedItems[i] + '/unlock', {}, {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Unlocked Successfully');
          this.getProductSheets(this.currentPage);
        });
    }
  }
 
  delete() {
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/product-sheets/' + this.selectedItems[i], {})
        .subscribe((data) => {
          this.selectedItems.splice(this.selectedItems.indexOf(this.selectedItems[i]), 1);
          this.notifier.notify('success', 'Deleted Successfully');
          this.getProductSheets(this.currentPage);
        });
    }
  }

  open(link) {
    window.open(link, "_blank");
  }
}
