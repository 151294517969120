import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Globals } from '../../globals';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatepickerOptions } from 'ng2-datepicker';
import { NotifierService } from 'angular-notifier';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-edit-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.css']
})
export class EditSheetComponent implements OnInit, OnDestroy {
  sheet: any = {
    tracks: [],
    formats: [],
    tracklistings: []
  };
  originalSheet = {};
  id: number;
  private sub: any;
  dateOptions: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 50,
    barTitleIfEmpty: 'Click to select a date'
  };
  settings: any = {
    configurations: [],
    packaging: []
  };

  loggedIn = true;
  tracks = [];
  trackSearchText = "";
  tracksTotalPages;
  tracksCurrentPage;
  diskTrackIsAddedTo;
  trackToAddFrom;
  territories;
  addToField;
  addToValue;
  addToDisplayField;
  addToArray;
  formatsToIncludeInPdf = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private http: ApiService,
    public globals: Globals) { }

  ngOnInit() {
    this.globals.currentPage = 'External Product Sheet';
    this.globals.spinnerLoading = false;
    this.originalSheet = JSON.parse(JSON.stringify(this.sheet));

    this.territories = ['Worldwide', ...this.globals.territories]

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        location.href = location.origin.concat('/#/product-sheets/' + this.id);
        this.getSheet(this.id);
      } else {
        location.href = location.origin.concat('/#');
      }
      this.http.get('/settings', {})
        .subscribe((settings) => {
          this.settings = settings;
        },
          (error) => {
            if (error.status === 401) {
              this.loggedIn = false;
            }
          });
    });
  }

  ngOnDestroy() {
    this.sub = [];
  }

  getSheet(id) {
    this.http.get('/product-sheets/external/' + id, {})
      .subscribe((data) => {
        this.sheet = data;
      });
  }

  save(finalize, callback?) {
    this.http.put('/product-sheets/external/' + this.id, this.sheet, {})
      .subscribe((data) => {
        this.sheet = data;
        this.notifier.notify('success', 'Product Sheet Saved Successfully');
      });
  }

  submit() {
    this.sheet.submitted = true;
    this.http.put('/product-sheets/external/' + this.id, this.sheet, {})
      .subscribe((data) => {
        this.sheet = data;
        this.notifier.notify('success', 'Product Sheet Submitted Successfully');
      });
  }


  clear(field) {
    this.sheet[field] = null;
  }

  addTrack() {
    this.sheet.tracks.push({
      uuid: parseInt((Math.random()*1000000).toString()),
      open: true,
      writers: [],
      performers: [],
      assetIds: [],
    })
  }

  addFormat() {
    this.sheet.formats.push({
      open: true,
    })
  }

  addTracklisting() {
    this.sheet.tracklistings.push({
      open: true,
      formats: [],
      disks: [],
    })
  }

  addWriter(track) {
    if (!track.writers) { track.writers = []; }
    track.writers.push({})
  }

  addPerformer(track) {
    if (!track.performers) { track.performers = []; }
    track.performers.push({})
  }

  removeElement(object, array, index) {
    object[array].splice(index, 1);
  }

  copyFormat(format) {
    const newFormat = JSON.parse(JSON.stringify(format));
    newFormat.name += ' - Copy';
    this.sheet.formats.push(newFormat)
  }

  removeFormat(i) {
    this.sheet.formats.splice(i, 1);
  }

  removeTrack(i) {
    this.sheet.tracks.splice(i, 1);
  }

  copyTracklisting(trackListing) {
    const newTracklisting = JSON.parse(JSON.stringify(trackListing));
    newTracklisting.formats = [];
    this.sheet.tracklistings.push(newTracklisting)
  }

  removeTracklisting(i) {
    this.sheet.tracklistings.splice(i, 1);
  }

  addDisc(tracklisting) {
    tracklisting.disks.push([])
  }

  addTrackToDisc(disk, track) {
    disk.push({ object: track })
  }

  removeTrackFromDisc(disk, index) {
    disk.splice(index, 1)
  }

  removeDisc(tracklisting, index) {
    tracklisting.disks.splice(index, 1)
  }

  getPlayingTime(track) {
    if (!track) return '00:00';

    var minutes = track.playingTimeMinutes || 0;
    var seconds = track.playingTimeSeconds || 0;

    return `${minutes.toLocaleString('en', { minimumIntegerDigits: 2 })}:${seconds.toLocaleString('en', { minimumIntegerDigits: 2 })}`
  }

  uploadSingleFile(track, files: FileList) {
    const index = this.sheet.tracks.indexOf(track);
    this.globals.progressBars[index] = [];

    const file = files[0];
    this.globals.progressBars[index].push({
      name: file.name,
      progress: 0
    });

    this.http.post('/tracks/' + this.id + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[index][0].progress = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {


            this.globals.progressBars[index][0].progress = 0;

            this.notifier.notify('success', 'File Uploaded Successfully');
            //Save asset to track
            track['assetIds'] = [data['assetId']];

            this.http.get('/assets/' + data['assetId'], 0)
              .subscribe((dataAsset) => {
                let asset = dataAsset;
                asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                this.http.put('/assets/' + data['assetId'], asset, {})
                  .subscribe((data) => {
                    track.filename = asset['name'];
                    this.getTrackDuration(track);
                  });
              });
          }
        });
      },
        (error) => {
          if (error.status === 401) {
            this.notifier.notify('error', 'Uploading files is only available to logged in users.');
          }
        });
  }

  stripFileName(name) {
    name = name.replace('&', '');

    return name;
  }

  getTracks(page) {
    let filter = { page: page };
    if (this.trackSearchText && this.trackSearchText != '')
      filter['text'] = this.trackSearchText;

    this.http.get('/tracks', filter)
      .subscribe((data) => {
        this.tracks = data['result'];
        this.tracksTotalPages = data['meta']['totalPages'];
        this.tracksCurrentPage = data['meta']['currentPage'];
      },
        (error) => {
          if (error.status === 401) {
            this.notifier.notify('error', 'This feature is only available to logged in users.');
          }
        });
  }

  getArtistsNames(track) {
    let artistsObjects = track['primaryArtistIds'].concat(track['featuringArtistIds']);
    let artists = '';

    for (let i = 0; i < artistsObjects.length; i++) {
      artists += artistsObjects[i].name + ', ';

    }

    artists = artists.substring(0, artists.length - 2);

    return artists;
  }

  addDBTrackToTracks(track) {
    this.sheet.tracks.push({
      _id: track._id,
      title: track.title,
      writers: track.publishingInfo.map((writer) => {
        return {
          name: writer.composer,
          split: writer.percentage,
          ukPublisher: writer.ukPublisher,
          prsTunecode: writer.prsTunecode,
          usPublisher: writer.usPublisher,
          hfSongCode: writer.hfSongCode,
          caPublisher: writer.caPublisher
        }
      }),
      performers: track.performers,
      isrc: track.isrc,
      yearRecorded: track.yearRecorded,
      yearFirstRelease: track.yearFirstRelease,
      countryOfCommission: track.countryOfCommission,
      countryOfRecording: track.countryOfRecording,
      recordingVenue: track.recordingVenue,
      producer: track.producer,
      mixer: track.mixer,
      engineer: track.engineer,
      mastering: track.mastering,
      playingTimeMinutes: track.playingTimeMinutes,
      playingTimeSeconds: track.playingTimeSeconds,
      explicit: track.explicit,
      remaster: track.remaster,
      medley: track.medley,
      containsSample: track.containsSample,
      assetIds: track.assetIds,
    });
    this.getTrackDuration(this.sheet.tracks[this.sheet.tracks.length - 1]);
  }

  bulkUploadFiles(files) {
    for (const file of files) {
      const track = {
        title: file.name.substring(0, file.name.lastIndexOf('.')),
        open: false,
        writers: [],
        performers: [],
        assetIds: [],
      };
      this.sheet.tracks.push(track);
      const index = this.sheet.tracks.indexOf(track);
      this.globals.progressBars[index] = [];
      this.globals.progressBars[index].push({
        name: file.name,
        progress: 0
      });

      this.http.post('/tracks/' + this.id + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
        .subscribe((data) => {
          let formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[index][0].progress = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {


              this.globals.progressBars[index][0].progress = 0;

              //Save asset to track
              this.sheet.tracks[index]['assetIds'] = [data['assetId']];

              this.http.get('/assets/' + data['assetId'], 0)
                .subscribe((dataAsset) => {
                  let asset = dataAsset;
                  asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                  this.http.put('/assets/' + data['assetId'], asset, {})
                    .subscribe((data) => {
                      this.sheet.tracks[index].filename = asset['name'];
                      this.getTrackDuration(this.sheet.tracks[index], index);
                    });
                });
            }
          });
        },
          (error) => {
            if (error.status === 401) {
              this.sheet.tracks.splice(index, 1);
              this.notifier.notify('error', 'Uploading files is only available to logged in users.');
            }
          });
    }
  }

  downloadPdf() {
    const formatsToInclude = [];
    this.formatsToIncludeInPdf.forEach((format) => {
      if (format.include) {
        formatsToInclude.push(format.name);
      }
    })
    this.http.getFile('/product-sheets/external/' + this.id + '/pdf', { formatsToInclude })
      .subscribe((x: any) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/pdf" });

        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = `${this.sheet.artist} - ${this.sheet.title}.pdf`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }

  addToAll() {
    this.sheet.tracks.forEach((track) => {
      track[this.addToField] = this.addToValue;
    })
  }

  addToAllArrays() {
    this.sheet.tracks.forEach((track) => {
      if (!track[this.addToArray]) track[this.addToArray] = [];

      if (!track[this.addToArray].includes(this.addToValue)) {
        track[this.addToArray].push(this.addToValue);
      }

    })
  }

  getTrackDuration(track, index?) {
    if (index || index === 0) { this.globals.progressBars[index][0].gettingDuration = true; }
    this.http.post('/product-sheets/' + this.id + '/duration', { track }, {})
    .subscribe((data) => {
      track.playingTimeMinutes = data['minutes']
      track.playingTimeSeconds = data['seconds']
      // Mark progress as 100% when duration is received
      if (index || index === 0) { 
        this.globals.progressBars[index][0].gettingDuration = false;
        this.notifier.notify('success', 'File Uploaded Successfully.');
      }
      })
  }

  getFormatsToInclude() {
    this.formatsToIncludeInPdf = [];
    this.sheet.formats.forEach((format) => {
      this.formatsToIncludeInPdf.push({
        name: format.name,
        include: true
      })
    })
  }

  moveUp(index, array) {
    if (index === 0) return;
    array[index] = array.splice(index - 1, 1, array[index])[0]
  }
  
  moveDown(index, array) {
    if (index === array.length - 1) return;
    array[index] = array.splice(index + 1, 1, array[index])[0]
  }
}
