import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent implements OnInit {
  @Input() catalogue;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateTo(url){
    this.router.navigate([url]);
  }
}
