import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private http: ApiService, private route: ActivatedRoute, private globals: Globals, private router: Router) { }


  user: any = {
    token: '',
    password: '',
    passwordVerification: ''
  }

  sub: any;

  error = '';

  ngOnInit() {
    this.globals.currentPage = 'Signup'

    this.sub = this.route.params.subscribe(params => {
      this.user['token'] = params['token'];
    });
  }

  signup() {

    if (this.user['password'] !== this.user['passwordVerification']) {
      this.error = 'Passwords don\'t match';
    }
    else {
      this.http.post('/users/signup', this.user, {})
        .subscribe((data) => {
          if(data['error']){
              this.error = 'Invalid invitation link. Please check your invitation email.'
          }else if(data['errorUsed']){
            this.error = 'This invitation link has already been used.'
        }else{
            this.router.navigate(['login']);
          }
        });
    }
  }
}
