import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import {DatepickerOptions} from 'ng2-datepicker';
import {Globals} from '../../globals';
import {ApiService} from '../../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import * as async from 'async';
import {HttpEventType} from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-external-track',
  templateUrl: './external-track.component.html',
  styleUrls: ['./external-track.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ExternalTrackComponent implements OnInit, OnDestroy {
  @Input() fromModal;
  @Input() release;
  @Input() trackData = null;
  @Input() trackIndex = 0;

  @Output() onSaveTrack = new EventEmitter();
  @Output() onDuplicateTrack = new EventEmitter();
  @Output() onAddPublisherToAll = new EventEmitter();
  @Output() onAddPerformerToAll = new EventEmitter();
  @Output() onDeleteTrack = new EventEmitter();

  track: any = {
    type: 'Track',
    title: 'Add Tittle',
    version: '',
    primaryArtistIds: [],
    featuringArtistIds: [],
    isrc: '',
    isrcYear: 0,
    isrcNumber: 0,
    primaryGenre: '',
    secondaryGenre: '',
    pYear: 0,
    pLine: '',
    composers: [],
    publishers: '',
    territories: [],
    rightsEndDate: null,
    initialProducer: '',
    lineUpComplete: false,
    performers: [],
    assetIds: [],
    projectIds: [],
    lyrics: '',
    exclusiveFor: null,
    exclusiveUntil: null,
    publishingInfo: [],
    rightsHolderCompanyName: 'Cooking Vinyl Limited',
    rightsHolderRightsType: 'Exclusive Licensee',
    rightsStartDate: null,
    recordingDate: null,
    countryOfRecording: 'GB',
    countryOfCommission: 'GB',
    initialProducerTerritory: 'GB'
  };
  originalTrack = {};
  id: any;
  private sub: any;
  assets: [{}];
  artists: [{}];
  options: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 50,
    barTitleIfEmpty: 'Click to select a date',
  };
  addTag;
  i;
  territories: {}[] = [];
  releaseId;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalTrack = JSON.parse(JSON.stringify(this.track));
    this.territories = this.globals.territories;

    this.getArtists();
    this.hydrateTrack();
  }



  ngOnDestroy() {}

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.track) === JSON.stringify(this.originalTrack)) {
      return true;
    } else {
      return false;
    }
  }




// ---------------------------------------------------------
//                    Track Functions
// ----------------------------------------------------------

  hydrateTrack() {
    this.track.type = this.trackData.type ? this.trackData.type  :  'Track';
    this.track.title = this.trackData.title ? this.trackData.title  :  'Add Tittle';
    this.track.version = this.trackData.version ? this.trackData.version  :  '';
    this.track.primaryArtistIds = this.trackData.primaryArtistIds ? this.trackData.primaryArtistIds  :  [];
    this.track.featuringArtistIds = this.trackData.featuringArtistIds ? this.trackData.featuringArtistIds  :  [];
    this.track.isrc = this.trackData.isrc ? this.trackData.isrc  :  '';
    this.track.isrcYear = this.trackData.isrcYear ? this.trackData.isrcYear  :  0;
    this.track.isrcNumber = this.trackData.isrcNumber ? this.trackData.isrcNumber  :  0;
    this.track.primaryGenre = this.trackData.primaryGenre ? this.trackData.primaryGenre  :  '';
    this.track.secondaryGenre = this.trackData.secondaryGenre ? this.trackData.secondaryGenre  :  '';
    this.track.pYear = this.trackData.pYear ? this.trackData.pYear  :  0;
    this.track.pLine = this.trackData.pLine ? this.trackData.pLine  :  '';
    this.track.composers = this.trackData.composers ? this.trackData.composers  :  [];
    this.track.publishers = this.trackData.publishers ? this.trackData.publishers  :  '';
    this.track.territories = this.trackData.territories ? this.trackData.territories  :  [];
    this.track.rightsEndDate = this.trackData.rightsEndDate ? this.trackData.rightsEndDate  :  null;
    this.track.initialProducer = this.trackData.initialProducer ? this.trackData.initialProducer  :  '';
    this.track.lineUpComplete = this.trackData.lineUpComplete ? this.trackData.lineUpComplete  :  false;
    this.track.performers = this.trackData.performers ? this.trackData.performers  :  [];
    this.track.assetIds = this.trackData.assetIds ? this.trackData.assetIds  :  [];
    this.track.projectIds = this.trackData.projectIds ? this.trackData.projectIds  :  [];
    this.track.lyrics = this.trackData.lyrics ? this.trackData.lyrics  :  '';
    this.track.exclusiveFor = this.trackData.exclusiveFor ? this.trackData.exclusiveFor  :  null;
    this.track.exclusiveUntil = this.trackData.exclusiveUntil ? this.trackData.exclusiveUntil  :  null;
    this.track.publishingInfo = this.trackData.publishingInfo ? this.trackData.publishingInfo  :  [];
    this.track.rightsHolderCompanyName = this.trackData.rightsHolderCompanyName ? this.trackData.rightsHolderCompanyName  :  'Cooking Vinyl Limited';
    this.track.rightsHolderRightsType = this.trackData.rightsHolderRightsType ? this.trackData.rightsHolderRightsType  :  'Exclusive Licensee';
    this.track.rightsStartDate = this.trackData.rightsStartDate ? this.trackData.rightsStartDate  :  null;
    this.track.recordingDate = this.trackData.recordingDate ? this.trackData.recordingDate  :  null;
    this.track.countryOfRecording = this.trackData.countryOfRecording ? this.trackData.countryOfRecording  :  'GB';
    this.track.countryOfCommission = this.trackData.countryOfCommission ? this.trackData.countryOfCommission  :  'GB';
    this.track.initialProducerTerritory = this.trackData.initialProducerTerritory ? this.trackData.initialProducerTerritory  :  'GB';

    this.originalTrack = JSON.parse(JSON.stringify(this.track));
    this.id = this.trackData._id ? this.trackData._id : '';

    if (this.release.title) {
      this.track.projectIds = this.release.projectIds;
      this.track.primaryArtistIds = this.release.primaryArtistIds;
      this.track.featuringArtistIds = this.release.featuringArtistIds;
      this.track.pYear = this.release.pYear;
      this.track.pLine = this.release.pLine;
      this.track.primaryGenre = this.release.primaryGenre;
      this.track.secondaryGenre = this.release.secondaryGenre;
      this.track.territories = this.release.territories;
    }
  }

  getTrack(id) {
    this.http.get('/tracks/' + id, 0)
      .subscribe((data) => {
        this.track = data;
        if (!this.track.priceTier) {
          this.track.priceTier = 'Front';
        }
        this.originalTrack = JSON.parse(JSON.stringify(data));
      });
  }

  saveTrack(track?) {

    if(track === undefined) {
      track = JSON.parse(JSON.stringify(this.track));
    }

    async.waterfall([
      callback => {
        this.stripPrimaryArtists((primaryArtists) => {
          track['primaryArtistIds'] = primaryArtists;
          callback(null, null);
        });
      },
      (arg, callback) => {
        this.stripFeaturingArtists((featuringArtists) => {
          track['featuringArtistIds'] = featuringArtists;
          callback(null, null);
        });
      }
    ], (err, result) => {

      if (this.id) {
        this.http.put('/tracks/' + this.id, track, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Updated Successfully');
            this.getTrack(this.id);
            this.onSaveTrack.emit(data);
          });
      } else {
        this.http.post('/tracks', track, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Created Successfully');
            this.http.put('/tracks/' + data['_id'], track, {})
              .subscribe((res) => {
                this.onSaveTrack.emit(res);
              });
          });
      }
    });
  }

  duplicateTrack() {
    const clone = Object.assign({}, this.track);
    this.onDuplicateTrack.emit({'track': clone});
  }

// ---------------------------------------------------------
//                    Composer / Publisher
// ----------------------------------------------------------

  deletePublishingInfo(i) {
    this.track['publishingInfo'].splice(i, 1);
  }

  addPublishingInfo() {
    this.track['publishingInfo'].push({});
  }

  addPublisherToAll(i) {
    const clone = Object.assign({}, this.track['publishingInfo'][i]);
    delete clone._id;
    clone.percentage = clone.prsTunecode = clone.usLicenseNo = clone.caLicenseNo = '';

    this.onAddPublisherToAll.emit({'publisher': clone, 'skip': this.trackIndex});
  }



// ---------------------------------------------------------
//                    Performer
// ----------------------------------------------------------

  addPerformer() {
    this.track['performers'].push({ id: new Date(), name: '', performerType: '', role: '' });
  }

  deletePerformer(i) {
    this.track['performers'].splice(i, 1);
  }

  addPerformerToAll (i) {

    const clone = Object.assign({}, this.track['performers'][i]);

    if (clone._id) {
      delete clone._id;
      clone['id'] = new Date();
    }

    this.onAddPerformerToAll.emit({'performer': clone, 'skip': this.trackIndex});
  }



// ---------------------------------------------------------
//                    Asset Functions
// ----------------------------------------------------------
  uploadAsset(files: FileList) {
    this.globals.progressBars[this.id] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.globals.progressBars[this.id].push({
        name: file.name,
        progress: 0
      });

      this.http.post('/tracks/' + this.id + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
        .subscribe((data) => {
          const formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[this.id][i].progress = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {


              this.globals.progressBars[this.id][i].progress = 0;

              this.notifier.notify('success', 'File Uploaded Successfully');
              // Save packshot to release
              this.track['assetIds'].push(data['assetId']);
              this.http.put('/tracks/' + this.id, this.track, {})
                .subscribe((dataReleases) => { });

              // save key to packshot
              this.http.get('/assets/' + data['assetId'], 0)
                .subscribe((dataAsset) => {
                  const asset = dataAsset;
                  asset['s3Key'] = JSON.stringify(response.body)
                    .substring(JSON.stringify(response.body)
                      .indexOf('<Key>') + 5, JSON.stringify(response.body)
                      .indexOf('</Key>'));
                  this.http.put('/assets/' + data['assetId'], asset, {})
                    .subscribe((res) => {
                      this.getTrack(this.id);
                    });
                });
            }
          });
        });
    }
  }

  removeAsset(asset) {
    this.track['assetIds'].splice(this.track['assetIds'].indexOf(asset), 1);
    this.saveTrack();
  }


// ---------------------------------------------------------
//                    Other Functions
// ----------------------------------------------------------

  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }

  stripPrimaryArtists(callback) {
    const artistIds = [];

    async.each(this.track['primaryArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      } else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    });
  }

  stripFeaturingArtists(callback) {

    const artistIds = [];
    async.each(this.track['featuringArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      } else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    });
  }

  stripFileName(name) {
    name = name.replace('&', '');

    return name;
  }

  clear(field) {
    this.track[field] = null;
  }
}


