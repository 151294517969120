import { Component, OnInit, HostListener } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { ComponentCanDeactivate } from '../libs/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, ComponentCanDeactivate {
  settings: any = {
    configurations: [],
    packaging: []
  }

  originalSettings = {};

  constructor(private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.getSettings();
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if(JSON.stringify(this.settings) === JSON.stringify(this.originalSettings))
      return true;
    else
      return false;
  }
  
  getSettings() {
    this.http.get('/settings', 0)
      .subscribe((data) => {
        this.settings = data;
        this.originalSettings = JSON.parse(JSON.stringify(data));
      });
  }

  saveSettings() {
    this.http.put('/settings', this.settings, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Updated Successfully');
        this.getSettings();
      });
  }

  customTrackBy(index: number, obj: any): any {
    return index;
}

}
