import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-territories',
  templateUrl: './territories.component.html',
  styleUrls: ['./territories.component.css']
})
export class TerritoriesComponent implements OnInit {
  @Input() selectedTerritories;
  @Output() selectedTerritoriesChange = new EventEmitter<boolean>();

  territories;

  checkedContinents: {} = {
    Africa: false,
    Americas: false,
    Asia: false,
    Europe: false,
    Oceania: false,
    World: false
  };

  constructor(private globals: Globals) { }

  ngOnInit() {
    this.territories = this.globals.territoriesWithContinents;
    this.isWorldChecked();
  }

  isCountryChecked(code) {
    if (this.selectedTerritories.indexOf(code) > -1)
      return true;
    else
      return false;
  }

  addCountry(code, continent) {
    if (this.selectedTerritories.indexOf(code) > -1)
      this.selectedTerritories = this.selectedTerritories.filter(item => item !== code);
    else
      if (this.selectedTerritories.indexOf(code) === -1)
        this.selectedTerritories.push(code);

    this.selectedTerritoriesChange.emit(this.selectedTerritories);
    this.isContinentChecked(continent);
  }

  checkWorld() {
    this.checkedContinents['World'] = !this.checkedContinents['World'];

    if (this.checkedContinents['World']) {
      for (var i = 0; i < this.territories['Africa'].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories['Africa'][i]['code']) === -1)
          this.selectedTerritories.push(this.territories['Africa'][i]['code']);
      }
      for (var i = 0; i < this.territories['Europe'].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories['Europe'][i]['code']) === -1)
          this.selectedTerritories.push(this.territories['Europe'][i]['code']);
      }
      for (var i = 0; i < this.territories['Asia'].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories['Asia'][i]['code']) === -1)
          this.selectedTerritories.push(this.territories['Asia'][i]['code']);
      }
      for (var i = 0; i < this.territories['Oceania'].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories['Oceania'][i]['code']) === -1)
          this.selectedTerritories.push(this.territories['Oceania'][i]['code']);
      }
      for (var i = 0; i < this.territories['Americas'].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories['Americas'][i]['code']) === -1)
          this.selectedTerritories.push(this.territories['Americas'][i]['code']);
      }

      this.checkedContinents['Africa'] = true;
      this.checkedContinents['Americas'] = true;
      this.checkedContinents['Asia'] = true;
      this.checkedContinents['Europe'] = true;
      this.checkedContinents['Oceania'] = true;
      this.checkedContinents['World'] = true;
    }
    else {
      this.selectedTerritories = [];

      this.checkedContinents['Africa'] = false;
      this.checkedContinents['Americas'] = false;
      this.checkedContinents['Asia'] = false;
      this.checkedContinents['Europe'] = false;
      this.checkedContinents['Oceania'] = false;
      this.checkedContinents['World'] = false;
    }

    this.selectedTerritoriesChange.emit(this.selectedTerritories);
  }

  checkContinent(continent) {
    this.checkedContinents[continent] = !this.checkedContinents[continent];

    if (this.checkedContinents[continent])
      for (var i = 0; i < this.territories[continent].length; i++) {
        if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) === -1)
          this.selectedTerritories.push(this.territories[continent][i]['code']);
      }
    else
      for (var i = 0; i < this.territories[continent].length; i++) {
        this.selectedTerritories = this.selectedTerritories.filter(item => item !== this.territories[continent][i]['code']);
      }

    this.selectedTerritoriesChange.emit(this.selectedTerritories);
  }

  isContinentChecked(continent) {
    this.checkedContinents[continent] = true;
    for (var i = 0; i < this.territories[continent].length; i++) {
      if (this.selectedTerritories.indexOf(this.territories[continent][i]['code']) === -1)
        this.checkedContinents[continent] = false;
    }
  }

  isWorldChecked() {
    if (this.selectedTerritories.length == 247) {
      this.checkedContinents['World'] = true;
      this.checkedContinents['Africa'] = true;
      this.checkedContinents['Americas'] = true;
      this.checkedContinents['Asia'] = true;
      this.checkedContinents['Europe'] = true;
      this.checkedContinents['Oceania'] = true;
      this.checkedContinents['World'] = true;
      return true;
    }
    else {
      this.checkedContinents['World'] = false;
      return false;
    }
  }
}
