import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { ApiService } from '../api.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.css']
})
export class ArtistsComponent implements OnInit {

  artists : {}[] = [];
  totalPages = 0;
  currentPage = 0;
  singleItemToDelete = -1;

  selectedItems = [];
  selectedItemsOnInit = true;
  selectedAll = false;
  searchTitle= '';
  
  constructor(private notifier : NotifierService, private route: ActivatedRoute, private router: Router, public globals: Globals, private http : ApiService) { }

  ngOnInit() {
    this.globals.currentPage = 'Artists'
    this.getArtists(1);
  }

  getArtists(page){
    let filter = { page: page };
    if(this.searchTitle && this.searchTitle != '')
      filter['searchTitle'] = this.searchTitle;

    this.http.get('/artists', filter)
    .subscribe((data) => {
      this.artists = data['result'];
      this.totalPages = data['meta']['totalPages'];
      this.currentPage = data['meta']['currentPage'];

    });
  }

  editArtist(id){
    this.router.navigate(['./'+id+'/edit'], { relativeTo: this.route });
  }

  onCreateArtist(){
    this.router.navigate(['./new'], {relativeTo: this.route});
  }
  
  deleteSingleItem(i) {
    this.http.delete('/artists/' + this.artists[i]['_id'], {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.getArtists(this.currentPage);
      });

  }

  isSelected(id){
    if(this.selectedItemsOnInit)
      return true;
    
    this.selectedItemsOnInit = false;  
    
      if(this.selectedItems.indexOf(id)>-1)
      return true;
    else 
      return false;
  }

  addToSelectedItems(id){
    if(this.selectedItems.indexOf(id)>-1){
      this.selectedItems.splice( this.selectedItems.indexOf(id), 1);
    }
    else{
      this.selectedItems.push(id);
    }
  }

  deleteMultipleItems(){
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.http.delete('/artists/' + this.selectedItems[i], {})
      .subscribe((data) => {
        this.selectedItems.splice( this.selectedItems.indexOf(this.selectedItems[i]), 1);
        this.notifier.notify('success', 'Deleted Successfully');
        this.getArtists(this.currentPage);
      });

      
    }
  }
}