import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { Globals } from '../../globals';
import { NotifierService } from 'angular-notifier';
import { HttpEventType } from '@angular/common/http';
import * as async from 'async';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-video',
  templateUrl: './new-video.component.html',
  styleUrls: ['./new-video.component.css']
})
export class NewVideoComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  video: any = {
    title: '',
    version: '',
    primaryArtistIds: [],
    featuringArtistIds: [],
    isrc: '',
    isrcYear: 0,
    isrcNumber: 0,
    primaryGenre: '',
    secondaryGenre: '',
    pYear: 0,
    pLine: '',
    composers: [''],
    publisher: '',
    territories: [],
    rightsHolderCompanyName: '',
    rightsHolderRightsType: '',
    rightsStartDate: new Date().getFullYear() + 1,
    rightsEndDate: new Date().getFullYear() + 1,
    recordingDate: new Date().getFullYear() + 1,
    countryOfRecording: '',
    countryOfCommission: '',
    initialProducer: '',
    initialProducerTerritory: '',
    lineUpComplete: false,
    performers: [],
    assetIds: [],
    projectIds: [],
    linkedChannel: '',
    channelLinkedTo: '',
    claimedBy: '',
    description: '',
    thumbnail_url: '',
    thumbnailTimestamp: '',
    publishingInfo: []
  };
  originalVideo = {};
  id: number;
  private sub: any;
  artists: [{}] = [{ _id: 'null', name: 'Loading..' }];
  projects: [{}] = [{ _id: 'null', title: 'Loading..' }];
  tracks: [{}] = [{ _id: 'null', title: 'Loading..' }];
  assets: [{}];
  options: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 1,
    barTitleIfEmpty: 'Click to select a date',
  };
  addTag;
  territories: {}[] = [];
  i;

  constructor(private router: Router, private route: ActivatedRoute, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalVideo = JSON.parse(JSON.stringify(this.video));
    this.territories = this.globals.territories;

    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.id) {
        this.getVideo(this.id);
      }

      if (this.globals.dataPassed) {
        this.video = this.globals.dataPassed;
        delete this.video['_id'];
        delete this.video['isrc'];
        this.globals.dataPassed = null;
      }
      this.getArtists();
      this.getProjects();
      this.getAssets();
      this.getTracks();
    });
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.video) === JSON.stringify(this.originalVideo))
      return true;
    else
      return false;
  }

  getVideo(id) {
    this.http.get('/videos/' + id, 0)
      .subscribe((data) => {

        this.video = data;

        if (data['thumbnail'])
          this.getThumbnail(this.video['thumbnail']);

        this.originalVideo = JSON.parse(JSON.stringify(this.video));
      });
  }

  getThumbnail(id) {
    this.http.get('/assets/' + this.video['thumbnail'], 0)
      .subscribe((data) => {
        this.video['thumbnail_url'] = data['publicLink'];
      });
  }

  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }

  getTracks() {
    this.http.get('/tracks', { limit: 1000 })
      .subscribe((data) => {
        this.tracks = data['result'];
      });
  }

  getProjects() {
    this.http.get('/projects', { limit: 1000 })
      .subscribe((data) => {
        this.projects = data['result'];
      });
  }

  saveVideo() {
    let video = JSON.parse(JSON.stringify(this.video));

    async.waterfall([
      callback => {
        this.stripPrimaryArtists((primaryArtists) => {
          video['primaryArtistIds'] = primaryArtists;
          callback(null, null);
        });
      },
      (arg, callback) => {
        this.stripFeaturingArtists((featuringArtists) => {
          video['featuringArtistIds'] = featuringArtists;
          callback(null, null);
        });
      }
    ], (err, result) => {

      if (this.id) {
        this.http.put('/videos/' + this.id, video, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Updated Successfully');
            this.getVideo(this.id);
            this.getArtists();
          });
      } else {
        this.http.post('/videos', video, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Created Successfully');
            // this.getVideo(data['_id']);
            // this.getArtists();
            this.video = this.originalVideo;
            this.router.navigate(['videos/' + data['_id'] + '/edit']);
          });
      }
    });
  }


  assignISRC() {
    this.http.get('/isrcs/assign', {})
      .subscribe((data) => {
        this.video['isrc'] = data;
      });
  }

  addComposer() {
    this.video['composers'].push('');
  }

  deleteComposer(i) {
    this.video['composers'].splice(i, 1);
  }

  addPerformer() {
    this.video['performers'].push({ id: new Date(), name: '', performerType: '', role: '' });
  }

  deletePerformer(i) {
    this.video['performers'].splice(i, 1);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  delete() {
    this.http.delete('/videos/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['videos']);
      });

  }

  uploadThumbnail(files: FileList) {
    let file = files.item(0);

    this.http.post('/videos/' + this.id + '/add_thumbnail', { filename: this.stripFileName(file.name), type: file.type }, {})
      .subscribe((data) => {
        let formData = new FormData();
        formData.append('key', data['credentials']['fields']['key']);
        formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
        formData.append('acl', data['credentials']['fields']['acl']);
        formData.append('policy', data['credentials']['fields']['policy']);
        formData.append('signature', data['credentials']['fields']['signature']);
        formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
        formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
        formData.append('file', file);

        this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
          if (response.type === HttpEventType.UploadProgress) {
            this.globals.progressBars[this.id+'-video'] = Math.round(100 * response.loaded / response.total);
          } else if (response.type === HttpEventType.Response) {

            this.globals.progressBars[this.id+ '-video'] = 0;

            this.notifier.notify('success', 'File Uploaded Successfully');
            //Save packshot to release
            this.video['thumbnail'] = data['thumbnail'];
            this.http.put('/videos/' + this.id, this.video, {})
              .subscribe((dataReleases) => { });

            //save key to thumbnail
            this.http.get('/assets/' + this.video['thumbnail'], 0)
              .subscribe((dataAsset) => {
                let asset = dataAsset;
                asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                this.http.put('/assets/' + this.video['thumbnail'], asset, {})
                  .subscribe((data) => {
                    this.getVideo(this.id);
                  });
              });
          }
        });
      });
  }


  stripPrimaryArtists(callback) {
    let artistIds = [];

    async.each(this.video['primaryArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }

  stripFeaturingArtists(callback) {

    let artistIds = [];

    async.each(this.video['featuringArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }


  getAssets() {
    this.http.get('/assets', { limit: 1000 })
      .subscribe((data) => {
        this.assets = data['result'];
      });
  }

  addAsset(id) {
    this.video['assetIds'].push(id);
    this.saveVideo();
  }

  removeAsset(asset) {
    this.video['assetIds'].splice(this.video['assetIds'].indexOf(asset), 1);
    this.saveVideo();
  }

  copy() {
    this.globals.dataPassed = this.video;
    this.router.navigate(['./videos/new']);
  }

  trackSelected(track) {
    this.http.get('/tracks/' + track._id, {})
      .subscribe((data) => {
        this.video['primaryArtistIds'] = data['primaryArtistIds'];
        this.video['featuringArtistIds'] = data['featuringArtistIds'];
        this.video['primaryGenre'] = data['primaryGenre'];
        this.video['secondaryGenre'] = data['secondaryGenre'];
        this.video['pYear'] = data['pYear'];
        this.video['pLine'] = data['pLine'];
        this.video['projectIds'] = data['projectIds'];
        this.video['composers'] = data['composers'];
      });
  }

  deletePublishingInfo(i) {
    this.video['publishingInfo'].splice(i, 1);
  }

  addPublishingInfo() {
    this.video['publishingInfo'].push({})
  }


  clear(field) {
    this.video[field] = null;
  }

  uploadAsset(files: FileList) {
    this.globals.progressBars[this.id] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.globals.progressBars[this.id].push({
        name : file.name, 
        progress: 0
      });

      this.http.post('/videos/' + this.id + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
        .subscribe((data) => {
          let formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[this.id][i].progress = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {


              this.globals.progressBars[this.id][i].progress = 0;

              this.notifier.notify('success', 'File Uploaded Successfully');
              //Save packshot to release
              this.video['assetIds'].push(data['assetId']);
              this.http.put('/videos/' + this.id, this.video, {})
                .subscribe((dataReleases) => { });

              //save key to packshot
              this.http.get('/assets/' + data['assetId'], 0)
                .subscribe((dataAsset) => {
                  let asset = dataAsset;
                  asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                  this.http.put('/assets/' + data['assetId'], asset, {})
                    .subscribe((data) => {
                      this.getVideo(this.id);
                    });
                });
            }
          });
        });
    }
  }

  stripFileName(name){
    name = name.replace('&', '');
    
    return name; 
  }

}