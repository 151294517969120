import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { DatepickerOptions } from 'ng2-datepicker';
import { Globals } from '../../globals';
import { ApiService } from '../../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import * as async from 'async';
import { HttpEventType } from '@angular/common/http';
import { ComponentCanDeactivate } from 'src/app/libs/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-new-track',
  templateUrl: './new-track.component.html',
  styleUrls: ['./new-track.component.css']
})
export class NewTrackComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @Input() fromModal;
  @Input() setFields(projectIds, primaryArtistIds, pYear, pLine, primaryGenre, secondaryGenre, territories) {
    this.track.projectIds = projectIds;
    this.track.primaryArtistIds = primaryArtistIds;
    this.track.pYear = pYear;
    this.track.pLine = pLine;
    this.track.primaryGenre = primaryGenre;
    this.track.secondaryGenre = secondaryGenre;
    this.track.territories = territories;
  };
  @Output() closeModal = new EventEmitter();

  track: any = {
    type: 'Track',
    title: '',
    version: '',
    primaryArtistIds: [],
    featuringArtistIds: [],
    isrc: '',
    isrcYear: 0,
    isrcNumber: 0,
    primaryGenre: '',
    secondaryGenre: '',
    pYear: 0,
    pLine: '',
    composers: [],
    publishers: '',
    territories: [],
    rightsEndDate: null,
    initialProducer: '',
    lineUpComplete: false,
    performers: [],
    assetIds: [],
    projectIds: [],
    lyrics: '',
    exclusiveFor: '',
    exclusiveUntil: null,
    publishingInfo: [],
    rightsHolderCompanyName: "Cooking Vinyl Limited",
    rightsHolderRightsType: "Exclusive Licensee",
    rightsStartDate: null,
    recordingDate: null,
    countryOfRecording: "GB",
    countryOfCommission: "GB",
    initialProducerTerritory: "GB"
  };
  originalTrack = {};
  id: number;
  private sub: any;
  artists: [{}] = [{ _id: 'null', name: 'Loading..' }];
  projects: [{}] = [{ _id: 'null', title: 'Loading..' }];
  assets: [{}];
  options: DatepickerOptions = {
    minYear: 1900,
    maxYear: new Date().getFullYear() + 50,
    barTitleIfEmpty: 'Click to select a date',
  };
  addTag;
  i;
  territories: {}[] = [];
  releaseId;
  fieldsToCopy = {};
  fieldsToCopySelectAll = false

  constructor(private router: Router, private route: ActivatedRoute, private notifier: NotifierService, private http: ApiService, public globals: Globals) { }

  ngOnInit() {
    this.originalTrack = JSON.parse(JSON.stringify(this.track));
    this.territories = this.globals.territories;
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];

      if (this.fromModal) {
        this.id = null;
      }

      if (this.id)
        this.getTrack(this.id);

      if (this.globals.dataPassed) {
        this.track = this.globals.dataPassed;
        delete this.track['_id'];
        delete this.track['isrc'];
        this.globals.dataPassed = null;
      }

      this.getArtists();
      this.getProjects();
      this.getAssets();
    });

    this.route.queryParams.subscribe(params => {
      if (params['releaseId'])
        this.releaseId = params['releaseId'];
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (JSON.stringify(this.track) === JSON.stringify(this.originalTrack))
      return true;
    else
      return false;
  }

  getTrack(id) {
    this.http.get('/tracks/' + id, 0)
      .subscribe((data) => {
        this.track = data;
        if (!this.track.priceTier)
          this.track.priceTier = 'Front';
        this.originalTrack = JSON.parse(JSON.stringify(data));
      });
  }
  getArtists() {
    this.http.get('/artists', { limit: 1000 })
      .subscribe((data) => {
        this.artists = data['result'];
      });
  }

  getProjects() {
    this.http.get('/projects', { limit: 1000 })
      .subscribe((data) => {
        this.projects = data['result'];
      });
  }

  saveTrack() {
    let track = JSON.parse(JSON.stringify(this.track));
    console.log(track);

    async.waterfall([
      callback => {
        this.stripPrimaryArtists((primaryArtists) => {
          track['primaryArtistIds'] = primaryArtists;
          callback(null, null);
        });
      },
      (arg, callback) => {
        this.stripFeaturingArtists((featuringArtists) => {
          track['featuringArtistIds'] = featuringArtists;
          callback(null, null);
        });
      }
    ], (err, result) => {

      if (this.id) {
        this.http.put('/tracks/' + this.id, track, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Updated Successfully');
            this.getTrack(this.id);
            this.getArtists();
          });
      } else {
        this.http.post('/tracks', track, {})
          .subscribe((data) => {
            this.notifier.notify('success', 'Created Successfully');
            this.http.put('/tracks/' + data['_id'], track, {})
              .subscribe((data) => {
                this.closeModal.emit(data);

                if (!this.fromModal) {
                  // this.getTrack(data['_id']);
                  // this.getArtists();
                  this.track = this.originalTrack;
                  this.router.navigate(['tracks/' + data['_id'] + '/edit']);
                }

              });
          });
      }
    });

    if (this.fromModal) {
      this.track = {
        title: '',
        version: '',
        primaryArtistIds: [],
        featuringArtistIds: [],
        isrc: '',
        isrcYear: 0,
        isrcNumber: 0,
        primaryGenre: '',
        secondaryGenre: '',
        pYear: 0,
        pLine: '',
        composers: [],
        publishers: '',
        territories: [],
        rightsHolderCompanyName: '',
        rightsHolderRightsType: '',
        rightsStartDate: new Date().getFullYear() + 1,
        rightsEndDate: new Date().getFullYear() + 1,
        recordingDate: new Date().getFullYear() + 1,
        countryOfRecording: '',
        countryOfCommission: '',
        initialProducer: '',
        initialProducerTerritory: '',
        lineUpComplete: false,
        performers: [],
        assetIds: [],
        projectIds: [],
        lyrics: '',
        exclusiveFor: '',
        exclusiveUntil: new Date().getFullYear() + 1
      };
    }
  }


  assignISRC() {
    this.http.get('/isrcs/assign', {})
      .subscribe((data) => {
        this.track['isrc'] = data;
      });
  }

  addComposer() {
    this.track['composers'].push('');
  }

  deleteComposer(i) {
    this.track['composers'].splice(i, 1);
  }

  addPerformer() {
    this.track['performers'].push({ id: new Date(), name: '', performerType: '', role: '' });
  }

  deletePerformer(i) {
    this.track['performers'].splice(i, 1);
  }

  editAsset(id) {
    this.router.navigate(['/assets/' + id + '/edit']);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  delete() {
    this.http.delete('/tracks/' + this.id, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Deleted Successfully');
        this.router.navigate(['tracks']);
      });

  }

  stripPrimaryArtists(callback) {
    let artistIds = [];

    async.each(this.track['primaryArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }

  stripFeaturingArtists(callback) {

    let artistIds = [];

    async.each(this.track['featuringArtistIds'], (artist, cb) => {
      if (!artist['_id']) {
        this.http.post('/artists', artist, {})
          .subscribe((data) => {
            artistIds.push(data['_id']);
            cb(null);
          });
      }
      else {
        artistIds.push(artist['_id']);
        cb(null);
      }
    }, (err) => {
      callback(artistIds);
    })
  }


  uploadAsset(files: FileList) {
    this.globals.progressBars[this.id] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.globals.progressBars[this.id].push({
        name: file.name,
        progress: 0
      });

      this.http.post('/tracks/' + this.id + '/add_asset', { filename: this.stripFileName(file.name), type: file.type }, {})
        .subscribe((data) => {
          let formData = new FormData();
          formData.append('key', data['credentials']['fields']['key']);
          formData.append('AWSAccessKeyId', data['credentials']['fields']['AWSAccessKeyId']);
          formData.append('acl', data['credentials']['fields']['acl']);
          formData.append('policy', data['credentials']['fields']['policy']);
          formData.append('signature', data['credentials']['fields']['signature']);
          formData.append('success_action_status', data['credentials']['fields']['success_action_status']);
          formData.append('Content-Type', data['credentials']['fields']['Content-Type']);
          formData.append('file', file);

          this.http.postToS3WithProgress(data['credentials']['url'], formData).subscribe((response) => {
            if (response.type === HttpEventType.UploadProgress) {
              this.globals.progressBars[this.id][i].progress = Math.round(100 * response.loaded / response.total);
            } else if (response.type === HttpEventType.Response) {


              this.globals.progressBars[this.id][i].progress = 0;

              this.notifier.notify('success', 'File Uploaded Successfully');
              //Save packshot to release
              this.track['assetIds'].push(data['assetId']);
              this.http.put('/tracks/' + this.id, this.track, {})
                .subscribe((dataReleases) => { });

              //save key to packshot
              this.http.get('/assets/' + data['assetId'], 0)
                .subscribe((dataAsset) => {
                  let asset = dataAsset;
                  asset['s3Key'] = JSON.stringify(response.body).substring(JSON.stringify(response.body).indexOf('<Key>') + 5, JSON.stringify(response.body).indexOf('</Key>'));

                  this.http.put('/assets/' + data['assetId'], asset, {})
                    .subscribe((data) => {
                      this.getTrack(this.id);
                    });
                });
            }
          });
        });
    }
  }

  getAssets() {
    this.http.get('/assets', { limit: 1000 })
      .subscribe((data) => {
        this.assets = data['result'];
      });
  }

  addAsset(id) {
    this.track['assetIds'].push(id);
    this.saveTrack();
  }

  removeAsset(asset) {
    this.track['assetIds'].splice(this.track['assetIds'].indexOf(asset), 1);
    this.saveTrack();
  }

  export() {
    this.http.post('/tracks/export', { ids: [this.id] }, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Exported Successfully');
      });
  }

  copy() {
    this.globals.dataPassed = this.track;
    this.router.navigate(['./tracks/new']);
  }

  backToRelease() {
    this.router.navigate(['releases/' + this.releaseId + '/edit']);
  }

  copyFields() {
    let objectToCopy = {};
    let track = JSON.parse(JSON.stringify(this.track));

    for (var property in this.fieldsToCopy) {
      if (this.fieldsToCopy.hasOwnProperty(property))
        if (track[property])
          objectToCopy[property] = track[property];
    }

    if (objectToCopy['primaryArtistIds'])
      for (let i = 0; i < objectToCopy['primaryArtistIds'].length; i++) {
        objectToCopy['primaryArtistIds'][i] = objectToCopy['primaryArtistIds'][i]._id;
      }

    if (objectToCopy['featuringArtistIds'])
      for (let i = 0; i < objectToCopy['featuringArtistIds'].length; i++) {
        objectToCopy['featuringArtistIds'][i] = objectToCopy['featuringArtistIds'][i]._id;
      }

    this.http.post('/releases/' + this.releaseId + '/copy_fields_to_all_tracks', objectToCopy, {})
      .subscribe((data) => {
        this.notifier.notify('success', 'Copied Successfully');
      });

  }

  deletePublishingInfo(i) {
    this.track['publishingInfo'].splice(i, 1);
  }

  addPublishingInfo() {
    this.track['publishingInfo'].push({})
  }

  clear(field) {
    this.track[field] = null;
  }

  copyFieldSelectAll() {
    this.fieldsToCopySelectAll = !this.fieldsToCopySelectAll;

    if (this.fieldsToCopySelectAll) {
      this.fieldsToCopy['primaryArtistIds'] = true;
      this.fieldsToCopy['featuringArtistIds'] = true;
      this.fieldsToCopy['primaryGenre'] = true;
      this.fieldsToCopy['secondaryGenre'] = true;
      this.fieldsToCopy['pYear'] = true;
      this.fieldsToCopy['pLine'] = true;
      this.fieldsToCopy['territories'] = true;
      this.fieldsToCopy['publisher'] = true;
      this.fieldsToCopy['composers'] = true;
      this.fieldsToCopy['countryOfRecording'] = true;
      this.fieldsToCopy['countryOfCommission'] = true;
      this.fieldsToCopy['rightsHolderCompanyName'] = true;
      this.fieldsToCopy['rightsHolderRightsType'] = true;
      this.fieldsToCopy['rightsStartDate'] = true;
      this.fieldsToCopy['rightsEndDate'] = true;
      this.fieldsToCopy['recordingDate'] = true;
      this.fieldsToCopy['initialProducer'] = true;
      this.fieldsToCopy['lineUpComplete'] = true;
      this.fieldsToCopy['performers'] = true;
      this.fieldsToCopy['priceTier'] = true;
    } else {
      this.fieldsToCopy['primaryArtistIds'] = false;
      this.fieldsToCopy['featuringArtistIds'] = false;
      this.fieldsToCopy['primaryGenre'] = false;
      this.fieldsToCopy['secondaryGenre'] = false;
      this.fieldsToCopy['pYear'] = false;
      this.fieldsToCopy['pLine'] = false;
      this.fieldsToCopy['territories'] = false;
      this.fieldsToCopy['publisher'] = false;
      this.fieldsToCopy['composers'] = false;
      this.fieldsToCopy['countryOfRecording'] = false;
      this.fieldsToCopy['countryOfCommission'] = false;
      this.fieldsToCopy['rightsHolderCompanyName'] = false;
      this.fieldsToCopy['rightsHolderRightsType'] = false;
      this.fieldsToCopy['rightsStartDate'] = false;
      this.fieldsToCopy['rightsEndDate'] = false;
      this.fieldsToCopy['recordingDate'] = false;
      this.fieldsToCopy['initialProducer'] = false;
      this.fieldsToCopy['lineUpComplete'] = false;
      this.fieldsToCopy['performers'] = false;
      this.fieldsToCopy['priceTier'] = false;
    }
  }

  stripFileName(name) {
    name = name.replace('&', '');

    return name;
  }
}


