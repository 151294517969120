import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class AppNavbarComponent implements OnInit {

  constructor(public router: Router, public globals : Globals) { }

  ngOnInit() {
  }


  logout(){
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
